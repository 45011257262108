import {
  WelcomePage,
  LoginComponent,
  ForgotComponent,
  ChangePwdComponent,
} from "./";

const routes = {
  path: "sign-in",
  component: WelcomePage,
  childRoutes: [
    { path: "", component: LoginComponent, isIndex: true },
    { path: "out", component: LoginComponent },
    { path: "forgot", component: ForgotComponent },
    { path: "change-password/:token", component: ChangePwdComponent },
    { path: ":partner/:token", component: LoginComponent },
  ],
};

export default routes;
