import { combineReducers } from "redux";
// import { routerReducer } from 'react-router-redux';
import { createRouterReducer } from "@lagunovsky/redux-react-router";
import history from "./history";
import homeReducer from "@home/redux/reducer";
import sharedReducer from "@shared/redux/reducer";
import dashboardReducer from "@dashboard/redux/reducer";

const reducerMap = {
  //router: connectRouter(history),
  router: createRouterReducer(history),
  home: homeReducer,
  shared: sharedReducer,
  dashboard: dashboardReducer,
};

export default combineReducers(reducerMap);
