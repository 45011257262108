import React, { useState } from "react";
import Popover from "react-popover";
import { Box, Text } from "grommet";

export default function RestrictedAreaIcon({ pkey, restrictedRoomNames }) {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      key={pkey}
      isOpen={open}
      body={`This area is restricted to rooms: ${(
        restrictedRoomNames || []
      ).join(", ")}.`}
      children={
        <Box
          key={pkey}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
          background="status-warning"
          round
          width={{ min: "20px" }}
          align="center"
          justify="center"
        >
          <Text color="white" size="xsmall">
            R
          </Text>
        </Box>
      }
    />
  );
}
