import React, { useState } from "react";
import { Box, Form, TextInput, FormField, Button, Text } from "grommet";
import AuthService from "../../services/auth.service";
import { presentToastErrorContent, presentToastError } from "@shared/Toast";

// import PropTypes from 'prop-types';

export default function ForgotComponent(props) {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const onEmailChange = (event) => setEmail(event.target.value);

  const handleSend = (e) => {
    AuthService.forgot(email).then(
      () => {
        setSent(true);
      },
      (error) => {
        if (error.response.status === 404) presentToastError("Email not found");
        else presentToastErrorContent(error);
      }
    );
  };

  return sent ? (
    <Box gap="medium">
      <Text weight="bold"> An email has been sent to your address. </Text>
      <Text> Follow the directions in the email to reset your password. </Text>
    </Box>
  ) : (
    <Form {...props} onSubmit={handleSend}>
      <Box gap="medium">
        <FormField label="Email" htmlFor="username">
          <TextInput
            id="username"
            placeholder="Enter your email"
            onChange={onEmailChange}
          />
        </FormField>
        <Button label="Send" primary type="submit" alignSelf="center" />
      </Box>
    </Form>
  );
}

ForgotComponent.propTypes = {};
ForgotComponent.defaultProps = {};
