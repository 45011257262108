import React, { useState } from "react";
import { Phone } from "grommet-icons";
import Popover from "react-popover";

export default function NoPhoneIcon(props) {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      key={props.pkey}
      isOpen={open}
      body="This guest has no phone number on file."
      children={
        <div
          key={props.pkey}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          <Phone size="small" color="red" />
        </div>
      }
    />
  );
}
