import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import {
  Layer,
  Box,
  Heading,
  Button,
  FormField,
  TextInput,
  Text,
  CheckBox,
} from "grommet";
import GuestService from "../../services/guest.service";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { BookingCalendarInput, PhoneInput } from "@shared";
import moment from "moment-timezone";
import { RoomSelect } from "./";
import {
  parsePhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { useFetchHotel } from "./redux/hooks";
import "./GuestModal.scss";

const STEP_GUEST_PHONE = 0;
const STEP_GUEST_INFO = 1;
const STEP_CHECK_IN = 2;
const STEP_COMPLETE = 3;

const defaultValues = {
  _id: 0,
  title: "Add",
  firstname: "",
  lastname: "",
  email: "",
  areaCode: "",
  phone: "",
  areacode: "",
  checkinDate: moment().hour(15),
  checkoutDate: moment().add(1, "days").hour(12),
  roomNumber: "",
  roomType: 0,
  accessDoors: [],
  currentDoors: [],
  invitationSent: false,
};

export default function GuestModal(props) {
  const { guestId, onClose, sendKeys } = props;
  const [step, setStep] = useState(0);
  const { hotel } = useFetchHotel();
  const [formValues, setFormValues] = useState(defaultValues);
  const [phoneNumberError, setPhoneNumberError] = useState("");

  useEffect(() => {
    if (guestId !== 0) {
      presentLoading();
      GuestService.getBooking(guestId).then(
        (response) => {
          dismissLoading();
          const booking = response.data;
          if (booking._id) {
            setFormValues({
              ...booking,
              title: "Edit",
              currentDoors: booking.accessDoors,
              checkinDate: moment.tz(booking.checkinDate, hotel.timezone),
              checkoutDate: moment.tz(booking.checkoutDate, hotel.timezone),
            });
          }

          if (
            sendKeys &&
            (booking.email || booking.phone) &&
            booking.checkinDate &&
            booking.checkoutDate
          )
            setStep(STEP_COMPLETE);
          else setStep(STEP_GUEST_INFO);
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
          setStep(STEP_GUEST_INFO);
        }
      );
    } else {
      setStep(STEP_GUEST_PHONE);
    }
  }, [guestId, hotel.timezone, sendKeys]);

  function handlePhoneInputChange(phone) {
    setPhoneNumberError("");
    let phoneObj = phone && parsePhoneNumber(phone);
    let areacode = "+" + ((phoneObj && phoneObj.countryCallingCode) || "");
    setFormValues({ ...formValues, phone, areacode });
  }

  function handleInputChange({ target: { name, value } }) {
    var values = { ...formValues };
    values[name] = value;
    setFormValues(values);
  }

  function handleRoomInputChange({ name, roomType }) {
    var values = { ...formValues, roomNumber: name };
    if (roomType) values.roomType = roomType;
    setFormValues(values);
  }

  // function handleRoomTypeInputChange({target:{name, value}}) {
  //   var values = {...formValues, roomType:value};
  //   if (formValues.roomNumber && formValues.roomNumber !== "-" && formValues.roomType === 0)
  //   values.roomNumber = "-";
  //   setFormValues(values);
  // }

  function handleCheckboxChange({ target: { name, checked } }) {
    var values = { ...formValues };
    values[name] = checked;
    setFormValues(values);
  }

  function handleBookingDatesChange(checkinDate, checkoutDate) {
    var checkinDateString = moment(checkinDate).format("YYYY-MM-DD");
    var checkoutDateString = moment(checkoutDate).format("YYYY-MM-DD");
    if (hotel.checkinTime)
      checkinDate = moment.tz(
        checkinDateString + " " + hotel.checkinTime,
        hotel.timezone
      );
    if (hotel.checkoutTime)
      checkoutDate = moment.tz(
        checkoutDateString + " " + hotel.checkoutTime,
        hotel.timezone
      );
    var values = { ...formValues, checkinDate, checkoutDate };
    setFormValues(values);
  }

  const handleUpdate = () => {
    if (formValues._id !== 0) {
      presentLoading();
      //console.log(formValues);
      GuestService.updateBooking(formValues).then(
        (response) => {
          dismissLoading();
          if (response.data.ok) {
            if (step === STEP_CHECK_IN) {
              if (formValues.requestPhotoID || formValues.requestCardImprint)
                onClose(true);
              else setStep(STEP_COMPLETE);
            } else {
              if (typeof formValues.requestPhotoID !== "boolean") {
                setFormValues({
                  ...formValues,
                  requestPhotoID: hotel.requestPhotoID,
                  requestCardImprint: hotel.requestCardImprint,
                  offerEarlyCheckin: hotel.offerEarlyCheckin,
                  offerUpgrade: hotel.offerUpgrade,
                  offerLateCheckout: hotel.offerLateCheckout,
                });
              }
              setStep(
                hotel.onlineCheckinEnabled ? STEP_CHECK_IN : STEP_COMPLETE
              );
            }
            if (response.data.nModified || response.data.guestModified)
              presentToastSuccess("Guest details updated");
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    }
  };

  const onCancel = (refresh) => {
    if (!guestId) {
      GuestService.deleteBooking(formValues._id).then(
        (response) => {},
        (error) => {}
      );
      onClose(true);
    } else {
      onClose(refresh);
    }
  };

  const handleEnterPhone = () => {
    if (
      isValidPhoneNumber(formValues.phone) ||
      formValues.phone === "+15550000000"
    ) {
      presentLoading();
      GuestService.addGuest({ phone: formValues.phone }).then(
        (response) => {
          dismissLoading();
          const booking = response.data;
          if (booking._id) {
            setStep(STEP_GUEST_INFO);
            setFormValues({
              ...defaultValues,
              ...booking,
              checkinDate: moment.tz(booking.checkinDate, hotel.timezone),
              checkoutDate: moment.tz(booking.checkoutDate, hotel.timezone),
              title: "Add",
            });
            if (booking.existing)
              presentToastSuccess("This traveler has a LOXE account");
            else presentToastSuccess("Guest successfully added");
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    } else {
      setPhoneNumberError("Enter a valid phone number");
    }
  };

  const gotoReservation = () => {
    window.location.href = "/guests/" + formValues._id;
  };

  return (
    <Layer position="center" modal onEsc={onClose} onClickOutside={onClose}>
      {step === STEP_GUEST_PHONE && (
        <Box pad="medium" gap="small" width="medium">
          <Heading level={3} margin="none">
            {" "}
            {formValues.title + " Guest"}{" "}
          </Heading>
          <Box direction="row" gap="small">
            <FormField
              label="Phone Number"
              className="phoneInputField"
              error={phoneNumberError}
            >
              <PhoneInput
                value={formValues.phone}
                onChange={handlePhoneInputChange}
              />
              <div className="international">{formValues.phone}</div>
            </FormField>
          </Box>
          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button label="Cancel" onClick={onClose} />
            <Button label="Next" onClick={handleEnterPhone} primary />
          </Box>
        </Box>
      )}
      {step === STEP_GUEST_INFO && (
        <Box pad="medium" gap="small" width="medium">
          <Heading level={3} margin="none">
            {" "}
            {formValues.title + " Guest"}{" "}
          </Heading>
          <Box direction="row" gap="small">
            <FormField label="First Name" alignSelf="end" fill>
              <TextInput
                name="firstname"
                value={formValues.firstname}
                onChange={handleInputChange}
              />
            </FormField>
            <FormField label="Last Name" alignSelf="end" fill>
              <TextInput
                name="lastname"
                value={formValues.lastname}
                onChange={handleInputChange}
              />
            </FormField>
          </Box>
          <BookingCalendarInput
            checkinDate={formValues.checkinDate}
            checkoutDate={formValues.checkoutDate}
            onChange={handleBookingDatesChange}
          />
          <FormField label="Email">
            <TextInput
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              placeholder="example@my.com"
            />
          </FormField>
          <FormField label="Assign a room" fill>
            <RoomSelect
              value={formValues.roomNumber}
              onChange={handleRoomInputChange}
            />
          </FormField>
          <Text alignSelf="center" color="dark-1" weight="bold">
            Phone Number: {formatPhoneNumberIntl(formValues.phone)}
          </Text>
          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button label="Cancel" onClick={onCancel} />
            <Button label="Save" onClick={handleUpdate} primary />
          </Box>
        </Box>
      )}
      {step === STEP_CHECK_IN && (
        <Box pad="medium" gap="small" width="large">
          <Heading level={4} margin="none">
            Online Check-in
          </Heading>
          <CheckBox
            name="requestPhotoID"
            checked={formValues.requestPhotoID}
            onChange={handleCheckboxChange}
            label="Request Photo ID + Selfie"
          />
          <CheckBox
            name="requestCardImprint"
            checked={formValues.requestCardImprint}
            onChange={handleCheckboxChange}
            label="Request Card Imprint"
          />
          <Heading level={4} margin="none">
            Upsell
          </Heading>
          <CheckBox
            name="offerEarlyCheckin"
            checked={formValues.offerEarlyCheckin}
            onChange={handleCheckboxChange}
            label="Offer Early Check-in"
          />
          <CheckBox
            name="offerUpgrade"
            checked={formValues.offerUpgrade}
            onChange={handleCheckboxChange}
            label="Offer Upgrade"
          />
          <CheckBox
            name="offerLateCheckout"
            checked={formValues.offerLateCheckout}
            onChange={handleCheckboxChange}
            label="Offer Late Check-out"
          />
          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button label="Close" onClick={() => onCancel(true)} />
            {formValues.invitationSent ? (
              <Button
                label="Update"
                onClick={handleUpdate}
                primary
                color="brand"
              />
            ) : (
              <Button
                label="Create Reservation"
                onClick={handleUpdate}
                primary
                color="brand"
              />
            )}
          </Box>
        </Box>
      )}
      {step === STEP_COMPLETE && (
        <Box pad="medium" gap="small" width="medium">
          <Heading level={4} margin="none">
            Reservation created
          </Heading>
          <Text center>
            The reservation for {formValues.firstname} {formValues.lastname} has
            been created for room {formValues.roomNumber} from{" "}
            {formValues.checkinDate.format("LLLL")} to{" "}
            {formValues.checkoutDate.format("LLLL")}.
          </Text>
          <Box
            direction="row"
            justify="between"
            margin={{ top: "medium" }}
            className="buttons"
          >
            <Button label="Close" onClick={() => onClose(true)} />
            <Button
              label="Go to reservation"
              onClick={() => gotoReservation()}
              primary
              color="brand"
            />
          </Box>
        </Box>
      )}
    </Layer>
  );
}
