import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Text,
  Accordion,
  AccordionPanel,
  Stack,
  Button,
  Spinner,
  Layer,
} from "grommet";
import {
  Mail,
  Chat,
  Notification,
  Key,
  Update,
  System,
  Flag,
} from "grommet-icons";
import { NoResults } from "@shared";
import { EventService } from "@services";
import { presentToastErrorContent } from "@shared/Toast";
import moment from "moment";

const eventIcons = {
  email: <Mail color="blue-1" />,
  sms: <Chat color="status-ok" />,
  push: <Notification />,
  keys: <Key color="status-warning" />,
  status: <Flag color="accent-2" />,
  update: <Update color="status-warning" />,
  pms: <System color="brand" />,
};

export default function EventList({ bookingId, timezone }) {
  const [events, setEvents] = useState([]);
  const [hasMoreEvents, setHasMoreEvents] = useState(false);
  const [isLoadingOlderEvents, setIsLoadingOlderEvents] = useState(false);
  const [longMessage, setLongMessage] = useState(null);

  const loadEvents = useCallback(() => {
    EventService.getEvents(bookingId).then(
      async (response) => {
        if (response.data) {
          const { events: nextEvents, hasMoreEvents: nextHasMoreMessage } =
            response.data;
          setEvents(nextEvents);
          setHasMoreEvents(nextHasMoreMessage);
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }, [bookingId]);

  const loadOlderEvents = async () => {
    setIsLoadingOlderEvents(true);
    const { data } = await EventService.getEvents(
      bookingId,
      events[events.length - 1]._id
    );
    const { events: olderEvents, hasMoreEvents: nextHasMoreMessage } = data;
    setEvents((prevEvents) => {
      return [...prevEvents, ...olderEvents];
    });
    setHasMoreEvents(nextHasMoreMessage);
    setIsLoadingOlderEvents(false);
  };

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  return events.length === 0 ? (
    <NoResults>This list is empty.</NoResults>
  ) : (
    <>
      {longMessage && (
        <Layer
          position="center"
          onClickOutside={() => setLongMessage(null)}
          onEsc={() => setLongMessage(null)}
          background="light-3"
        >
          <Box
            pad="small"
            round
            width={{ max: "large" }}
            height={{ max: "large" }}
            overflow={{ vertical: "auto" }}
          >
            {longMessage.split(/\n/).map((chunk, i) => (
              <Text key={`chunk-long-${i}`}>{chunk}</Text>
            ))}
            <Button
              label="Close"
              onClick={() => setLongMessage(null)}
              small
              alignSelf="center"
            />
          </Box>
        </Layer>
      )}
      <Accordion width="xlarge">
        {events.map((event) => (
          <AccordionPanel
            key={event._id}
            label={
              <Box
                direction="row"
                justify="between"
                align="center"
                pad="small"
                gap="small"
              >
                <Stack anchor="center">{eventIcons[event.type]}</Stack>
                <Box style={{ minWidth: "150px" }} align="center">
                  <Text weight="bold" size="xsmall">
                    {moment.tz(event.created, timezone).format("lll")}
                  </Text>
                </Box>
                <Text
                  margin={{ left: "small" }}
                  truncate
                  color={event.error ? "red" : undefined}
                >
                  {event.message}
                </Text>
              </Box>
            }
          >
            <Box pad="small" background="light-3">
              {event.message.split(/\n/).map((chunk, i) => (
                <Text key={`chunk-${event.id}-${i}`}>{chunk}</Text>
              ))}
              {event.longMessage && (
                <Button
                  label={event.type === "pms" ? "View payload" : "View more"}
                  onClick={() => setLongMessage(event.longMessage)}
                  primary
                  small
                  alignSelf="start"
                  margin="small"
                />
              )}
            </Box>
          </AccordionPanel>
        ))}
      </Accordion>
      {hasMoreEvents && (
        <Box center>
          <Button
            style={
              isLoadingOlderEvents
                ? {
                    paddingLeft: 90,
                    width: 205,
                  }
                : {}
            }
            label={
              isLoadingOlderEvents ? (
                <Spinner size="xsmall" />
              ) : (
                "Load older events"
              )
            }
            onClick={loadOlderEvents}
            active
            alignSelf="center"
            small
          />
        </Box>
      )}
    </>
  );
}
