import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import {} from "./redux/hooks";
import { Box, Header, Heading, Button, DataTable, Text } from "grommet";
import { View, Trash } from "grommet-icons";
import GuestService from "../../services/guest.service";
import { capitalize, onDatatableSearch } from "@common/utils";
import {
  BookingDates,
  AppInstalled,
  NoPhoneIcon,
  BookingStatus,
  NoResults,
} from "@shared/";
import { presentAlertPrompt } from "@shared/Alert";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { presentLoading, dismissLoading } from "@shared/Loading";

const fetchGuests = (setGuestList, setAllGuestList) => {
  presentLoading();
  GuestService.getBookings("archived").then(
    (response) => {
      var list = response.data.map((booking) => ({
        id: booking._id,
        bookingId: booking.bookingId,
        name:
          capitalize(booking.firstname) + " " + capitalize(booking.lastname),
        appInstalled: booking.appInstalled,
        room: booking.roomNumber ? booking.roomNumber : "-",
        dates: (
          <BookingDates
            checkinDate={booking.checkinDate}
            checkoutDate={booking.checkoutDate}
          />
        ),
        status: booking.status,
        statusName: booking.status.name,
        phone: booking.phone,
      }));
      dismissLoading();
      setGuestList(list);
      setAllGuestList(list);
    },
    (error) => {
      dismissLoading();
      setGuestList([]);
      setAllGuestList([]);
    }
  );
};

export default function ArchivedPage() {
  const [guestList, setGuestList] = useState([]);
  const [allGuestList, setAllGuestList] = useState([]);

  const columns = [
    {
      property: "bookingId",
      header: "Ref.",
      search: true,
    },
    {
      property: "name",
      header: "Name",
      search: true,
      render: (datum) => (
        <Box direction="row" gap="small" align="center">
          <Text>{datum.name}</Text>
          {datum.appInstalled && <AppInstalled pkey={datum.id} />}
          {!datum.phone && !datum.appInstalled && (
            <NoPhoneIcon pkey={`nophone-${datum.id}`} />
          )}
        </Box>
      ),
    },
    {
      property: "room",
      header: "Room",
      align: "center",
      search: true,
    },
    {
      property: "dates",
      header: "Booking Dates",
      // render: datum =>
      //   datum.date && new Date(datum.date).toLocaleDateString('en-US'),
      align: "center",
    },
    {
      property: "statusName",
      header: "Status",
      align: "center",
      search: true,
      render: (datum) => <BookingStatus status={datum.status} />,
    },
    {
      property: "actions",
      header: "Actions",
      align: "center",
      render: (datum) => (
        <Box direction="row" gap="medium">
          <Button
            hoverIndicator="brand"
            icon={<View size="small" />}
            size="small"
            primary
            color="neutral-2"
            onClick={() => handleGuestDetails(datum.id)}
          />
          {datum.status.slug !== "cancelled" && (
            <Button
              hoverIndicator="dark-1"
              icon={<Trash size="small" />}
              size="small"
              primary
              color="status-critical"
              onClick={() => handleRemoveBooking(datum.id)}
            />
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    fetchGuests(setGuestList, setAllGuestList);
  }, []);

  const handleGuestDetails = (guestId) => {
    window.location.href = "archived/" + guestId;
  };

  const handleRemoveBooking = (guestId) => {
    function removeGuest() {
      GuestService.deleteBooking(guestId).then(
        (response) => {
          presentToastSuccess("Reservation successfully removed.");
          fetchGuests(setGuestList, setAllGuestList);
        },
        (error) => {
          presentToastErrorContent(error);
          fetchGuests(setGuestList, setAllGuestList);
        }
      );
    }

    presentAlertPrompt({
      title: "Are you sure?",
      message:
        "This will cancel the reservation only on LOXE's system, not on your PMS.",
      onOK: removeGuest,
      buttonOKText: "Delete",
      buttonOKColor: "status-critical",
    });
  };

  return (
    <Box align="center" justify="start" fill pad="medium">
      <Header
        align="center"
        direction="row"
        justify="between"
        gap="medium"
        fill="horizontal"
      >
        <Heading level="2">
          Archives {allGuestList && "(" + allGuestList.length + ")"}
        </Heading>
      </Header>
      <Box fill="horizontal">
        {allGuestList.length ? (
          <DataTable
            columns={columns}
            data={guestList}
            primaryKey="id"
            onClickRow={(event) => {
              //console.log(event.target.localName);
              // if (event.target.localName!=='button' && event.target.localName!=='svg')
              //   handleGuestModal(event.datum.id);
            }}
            onSearch={(search) =>
              onDatatableSearch(search, allGuestList, setGuestList)
            }
          />
        ) : (
          <NoResults>This list is empty.</NoResults>
        )}
      </Box>
    </Box>
  );
}

ArchivedPage.propTypes = {};
ArchivedPage.defaultProps = {};
