import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import { useFetchDoors } from "./redux/hooks";
import { Box, Heading, Button } from "grommet";
import { Download } from "grommet-icons";
import { SettingsDoorTable } from "./";
import { PmsService } from "../../services";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { CAN_IMPORT_FROM_PMS } from "@common/constants";
import "./SettingsRoomsAreas.scss";
export default function SettingsRoomsAreas() {
  const { rooms, areas, fetchDoors } = useFetchDoors();

  useEffect(() => {
    fetchDoors();
  }, [fetchDoors]);

  const handleImport = () => {
    presentLoading();
    PmsService.importDoors().then(
      (response) => {
        fetchDoors();
        dismissLoading();
      },
      (error) => {
        dismissLoading();
      }
    );
  };

  return (
    <div className="doorTablesContainer">
      <Box direction="row" justify="between">
        <Heading level="2">Door Locks</Heading>
        {CAN_IMPORT_FROM_PMS && (
          <Button
            style={{ maxHeight: 30 }}
            alignSelf="center"
            icon={<Download size="small" />}
            label="Import from PMS"
            primary
            color="status-warning"
            onClick={handleImport}
          />
        )}
      </Box>
      <Box direction="row" gap="large">
        <SettingsDoorTable
          title="Rooms"
          type="room"
          doors={[...rooms, ...areas]}
          onRefresh={fetchDoors}
        />
        <SettingsDoorTable
          title="Areas"
          type="area"
          doors={[...rooms, ...areas]}
          onRefresh={fetchDoors}
        />
      </Box>
    </div>
  );
}

SettingsRoomsAreas.propTypes = {};
SettingsRoomsAreas.defaultProps = {};
