import React from "react";
import { Layer, Box } from "grommet";

class Loading extends React.Component {
  constructor() {
    super();
    this.state = { loading: false };
    window.loading = this;
  }

  present(timer) {
    this.setState({ ...this.state, loading: true });
    setTimeout(() => {
      this.setState({ ...this.state, loading: false });
    }, timer || 10000);
  }

  dismiss() {
    this.setState({ ...this.state, loading: false });
  }

  render() {
    return (
      this.state.loading && (
        <Layer animation="fadeIn" className="transparent">
          <Box fill align="center" justify="center" background="transparent">
            <div className="sk-folding-cube">
              <div className="sk-cube1 sk-cube"></div>
              <div className="sk-cube2 sk-cube"></div>
              <div className="sk-cube4 sk-cube"></div>
              <div className="sk-cube3 sk-cube"></div>
            </div>
          </Box>
        </Layer>
      )
    );
  }
}

export default Loading;
export function presentLoading(timer) {
  window.loading.present(timer);
}
export function dismissLoading() {
  window.loading.dismiss();
}
