import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, Button, TextArea, Text, Image, Spinner } from "grommet";
import io from "socket.io-client";
import moment from "moment-timezone";
import { CommunicationService, NotificationService } from "@services";
import { presentToastErrorContent } from "@shared/Toast";
import { SERVER_URL } from "@services/api";
import { useFetchHotel } from "@dashboard/redux/hooks";

import "./Conversation.scss";

export default function Conversation({ bookingId, guestName }) {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [connectedToSocket, setConnectedToSocket] = useState(false);
  const [isGuestOnline, setIsGuestOnline] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [isLoadingEarlierMessages, setIsLoadingEarlierMessages] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [socket, setSocket] = useState(null);
  const { hotel } = useFetchHotel();

  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messageEndRef)
      messageEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  };

  const markLastMessagesAsRead = useCallback(
    async (pMessages) => {
      const sentMessages = pMessages.filter(
        (m) => !m.fromHotel && m.status === "SENT"
      );
      for (const message of sentMessages) {
        socket.emit("messageRead", message);
      }
      if (sentMessages.length > 0) {
        const lastMessage = pMessages[pMessages.length - 1];
        if (!lastMessage.fromHotel) {
          await CommunicationService.markAsRead(lastMessage._id);
        }
      }
    },
    [socket]
  );

  const loadMessages = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await CommunicationService.getMessages(bookingId);
      if (response.data) {
        const { messages: nextMessages, hasMoreMessages: nextHasMoreMessage } =
          response.data;
        setMessages(nextMessages);
        setHasMoreMessages(nextHasMoreMessage);
        await NotificationService.removeMessage(bookingId);
        setTimeout(() => {
          scrollToBottom();
          markLastMessagesAsRead(nextMessages);
        }, 1000);
      }
    } catch (error) {
      presentToastErrorContent(error);
    }
  }, [bookingId, markLastMessagesAsRead]);

  const loadEarlierMessages = async () => {
    setIsLoadingEarlierMessages(true);
    const { data } = await CommunicationService.getMessages(
      bookingId,
      messages[0]._id
    );
    const { messages: newMessages, hasMoreMessages: nextHasMoreMessage } = data;
    setMessages((prevMessages) => {
      return [...newMessages, ...prevMessages];
    });
    setHasMoreMessages(nextHasMoreMessage);
    setIsLoadingEarlierMessages(false);
  };

  useEffect(() => {
    if (!isLoading) {
      loadMessages();
    }
  }, [loadMessages, isLoading]);

  useEffect(() => {
    const newSocket = io(SERVER_URL, {
      transports: ["websocket"],
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket error:", error);
    });

    newSocket.on("connect", () => {
      console.log("Connected to server");
      setConnectedToSocket(true);
      newSocket.emit("join", { bookingId, isHotel: true });
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [bookingId]);

  useEffect(() => {
    if (socket) {
      socket.on("receiveMessage", async (message) => {
        if (!message.fromHotel) {
          setMessages((prevMessages) => [...prevMessages, message]);

          await CommunicationService.markAsRead(message._id);
          socket.emit("messageRead", message);
          setTimeout(scrollToBottom, 500);
        }
      });

      socket.on("onUsersChange", async (onlineStatuses) => {
        setIsGuestOnline(onlineStatuses?.guest);
      });

      socket.on("messageRead", (message) => {
        if (!message.fromHotel) return;

        setMessages((prevMessages) => {
          const index = prevMessages.findIndex((m) => m._id === message._id);
          if (index !== -1) {
            const nextMessages = [...prevMessages];
            nextMessages[index] = { ...message, status: "READ" };
            return nextMessages;
          }
          return prevMessages;
        });
      });
    }
  }, [socket]);

  const handleSendMessage = async () => {
    if (currentMessage?.trim().length === 0) return;
    try {
      const { data: newMessage } = await CommunicationService.sendMessage(
        bookingId,
        currentMessage.trim()
      );
      if (newMessage) {
        setCurrentMessage("");
        setMessages([...messages, newMessage]);
        // Emit the message to the server
        socket?.emit("sendMessage", newMessage);
      }
    } catch (error) {
      presentToastErrorContent(error.message);
    }
  };

  return (
    <>
      <Box direction="row" justify="between">
        <Text weight="bold" size="medium" direction="row">
          {guestName}{" "}
          {isGuestOnline ? (
            <Text color="status-ok">(Online)</Text>
          ) : (
            <Text color="status-warning">(SMS)</Text>
          )}
        </Text>
        {!connectedToSocket && (
          <Button
            label="Refresh"
            onClick={() => loadMessages()}
            primary
            color="brand"
            alignSelf="end"
          />
        )}
      </Box>
      <Box gap="small" width="100%">
        <Box
          height="medium"
          round
          width="xlarge"
          overflow="scroll"
          pad="medium"
          margin="medium"
          background="light-1"
        >
          <Box flex="grow">
            {hasMoreMessages && (
              <Button
                style={
                  isLoadingEarlierMessages
                    ? {
                        paddingLeft: 90,
                        width: 205,
                      }
                    : {}
                }
                label={
                  isLoadingEarlierMessages ? (
                    <Spinner size="xsmall" />
                  ) : (
                    "Load earlier messages"
                  )
                }
                onClick={loadEarlierMessages}
                active
                alignSelf="center"
                small
              />
            )}
            {messages &&
              messages.map((message) => (
                <Box key={message._id}>
                  {message.photo && (
                    <Image
                      className="photo"
                      src={message.photo}
                      fit="cover"
                      width="200px"
                      round="small"
                      alignSelf={message.fromHotel ? "end" : "start"}
                    />
                  )}
                  {message.text && (
                    <Box
                      className="message"
                      width="fit-content"
                      alignSelf={message.fromHotel ? "end" : "start"}
                      background={message.fromHotel ? "accent-1" : "light-4"}
                      round="small"
                      pad="small"
                    >
                      {message.text}
                    </Box>
                  )}
                  <Text
                    className="date"
                    size="11px"
                    style={{ fontStyle: "italic" }}
                    alignSelf={message.fromHotel ? "end" : "start"}
                  >
                    {moment.tz(message.created, hotel.timezone).format("llll")}{" "}
                    {message.fromHotel && `• ${message.status.toLowerCase()}`}
                  </Text>
                </Box>
              ))}
          </Box>
          <div ref={messageEndRef}></div>
        </Box>
        <Box direction="row">
          <Box
            background="light-1"
            margin="small"
            animation="fadeIn"
            height="100px"
            width="100%"
          >
            <TextArea
              fill
              value={currentMessage}
              onChange={(event) => setCurrentMessage(event.target.value)}
              placeholder="Type your message here..."
              resize={false}
            />
          </Box>
          <Button
            label="Send"
            onClick={handleSendMessage}
            primary
            alignSelf="center"
          />
        </Box>
      </Box>
    </>
  );
}
