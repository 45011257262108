import React from "react";
// import PropTypes from 'prop-types';
import { Box, Text, Image } from "grommet";
import noResultsImage from "../../images/no-results.svg";

export default function NoResults({ children, ...rest }) {
  return (
    <Box alignSelf="center" width="large" pad="large" {...rest}>
      <Text alignSelf="center" style={{ marginTop: 100, textAlign: "center" }}>
        {children}
      </Text>
      <Image
        style={{ maxWidth: "600px" }}
        fit="cover"
        src={noResultsImage}
        className="no-results-image"
      />
    </Box>
  );
}
