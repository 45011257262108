import axios from "axios";
import { API_URL, authHeader } from "./api";

const ENDPOINT_URL = API_URL + "passcode/";

class PasscodeService {
  delete(bookingId, passcodeId) {
    return axios.delete(ENDPOINT_URL + "b/" + bookingId + "/" + passcodeId, {
      headers: authHeader(),
    });
  }

  getFromLock(passcodeId) {
    return axios.get(ENDPOINT_URL + passcodeId, {
      headers: authHeader(),
    });
  }
}

export default new PasscodeService();
