import React, { useEffect, useState } from "react";
import { Box, Form, TextInput, FormField, Button } from "grommet";
import AuthService from "../../services/auth.service";
import { presentToastErrorContent, presentToastError } from "@shared/Toast";
import { useParams } from "react-router-dom";

// import PropTypes from 'prop-types';

export default function LoginComponent(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const onUsernameChange = (event) => setUsername(event.target.value);
  const onPasswordChange = (event) => setPassword(event.target.value);

  const { token, partner } = useParams();

  useEffect(() => {
    if (token) {
      AuthService.ssoLogin(partner, token).then(
        () => {
          window.location.href = "/";
        },
        (error) => {
          if (error.response && error.response.status === 404)
            presentToastError("User not found");
          else presentToastErrorContent(error);
        }
      );
    }
  }, [partner, token]);

  const handleLogin = () => {
    AuthService.login(username, password).then(
      () => {
        window.location.href = "/";
      },
      (error) => {
        if (error.response && error.response.status === 404)
          presentToastError("User not found");
        else presentToastErrorContent(error);
      }
    );
  };

  return (
    <Form onSubmit={handleLogin}>
      <Box gap="medium">
        <FormField label="Username" htmlFor="username">
          <TextInput
            id="username"
            placeholder="Enter your username"
            onChange={onUsernameChange}
          />
        </FormField>

        <FormField label="Password" htmlFor="password">
          <TextInput
            id="password"
            placeholder="Enter your password"
            type="password"
            onChange={onPasswordChange}
          />
        </FormField>
        <Button label="Login" primary type="submit" alignSelf="center" />
        <p align="center">
          <a href="/sign-in/forgot">Forgot your password?</a>
        </p>
      </Box>
    </Form>
  );
}

LoginComponent.propTypes = {};
LoginComponent.defaultProps = {};
