/* This is the Root component mainly initializes Redux and React Router. */

import React from "react";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import store from "./common/store";
import routeConfig from "./common/routeConfig";
import history from "./common/history";

const routes = routeConfig.map((item) => (
  <Route key={item.path} element={item.component} path={item.path} />
));

function Root() {
  return (
    <Provider store={store}>
      <ReduxRouter history={history}>
        <Routes>{routes}</Routes>
      </ReduxRouter>
    </Provider>
  );
}

export default Root;
