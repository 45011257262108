import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextInput,
  Heading,
  Layer,
  Text,
  TextArea,
  FormField,
  CheckBox,
} from "grommet";
import { Add, Edit, Trash } from "grommet-icons";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import ProductService from "@services/product.service";
import PhotoService from "@services/photo.service";
import { PhotoUploader, ValidStatusesSelect, AddProductOption } from "./";
import { roundPrice } from "@common/utils";
import { PriceInput } from "@shared";
import "./AddProduct.scss";

const defaultValues = {
  _id: 0,
  name: "",
  duration: 0,
  price: 0,
  description: "",
  photo: "",
  taxPercent: "",
  tax: "",
  subCategory: "",
  validStatuses: ["prearrival", "checkedin"],
  options: [],
  active: false,
};

export default function AddProduct(props) {
  const { category, productId, onClose } = props;
  const [product, setProduct] = useState(defaultValues);
  const [photo, setPhoto] = useState(undefined);
  const [openEditOption, setOpenEditOption] = useState(false);
  const [optionIndex, setOptionIndex] = useState(-1);

  function handleInputChange({ target: { name, value } }) {
    var values = { ...product };
    values[name] = value;
    setProduct(values);
  }

  function handleTaxPercentChange({ target: { value } }) {
    var values = { ...product, taxPercent: value };
    if (product.price) values.tax = roundPrice((product.price * value) / 100);
    setProduct(values);
  }

  function handleTaxChange({ target: { value } }) {
    var values = { ...product, tax: value };
    if (product.price)
      values.taxPercent = roundPrice((value / product.price) * 100);
    setProduct(values);
  }

  function handlePriceChange({ target: { value } }) {
    var values = { ...product, price: value };
    if (product.taxPercent)
      values.tax = roundPrice((product.taxPercent * value) / 100);
    setProduct(values);
  }

  function handleCheckboxChange({ target: { name, checked } }) {
    var values = { ...product };
    values[name] = checked;
    setProduct(values);
  }

  const handleAdd = () => {
    let validStatuses = [];
    if (product.validStatuses.length > 0) {
      validStatuses =
        product.validStatuses[0] && product.validStatuses[0].id
          ? product.validStatuses.map((h) => h.id)
          : product.validStatuses;
    }
    if (productId === 0) {
      ProductService.addProduct(category._id, {
        ...product,
        validStatuses,
      }).then(
        async (response) => {
          const product = response.data && response.data.product;
          if (product) await uploadPhoto(product._id);
          onClose(true);
          presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    } else {
      ProductService.updateProduct(category._id, {
        ...product,
        validStatuses,
      }).then(
        async (response) => {
          await uploadPhoto(productId);
          onClose(true);
          presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
  };

  const uploadPhoto = async (productId) => {
    if (!photo) return;
    await ProductService.uploadPhoto(productId, photo).then(
      (response) => {
        if (response.data.success) {
          //presentToastSuccess("Photo Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const editOption = (optionIndex) => {
    setOptionIndex(optionIndex);
    setOpenEditOption(true);
  };

  const removeOption = (optionIndex) => {
    var nextOptions = [...product.options];
    nextOptions.splice(optionIndex, 1);
    setProduct({ ...product, options: nextOptions });
  };

  const handleOptionChanged = (option) => {
    if (!product.options || product.options.length === 0) {
      setProduct({ ...product, options: [option] });
    } else {
      var nextOptions = [...product.options];
      if (optionIndex < 0 || optionIndex >= nextOptions.length)
        nextOptions.push(option);
      else nextOptions.splice(optionIndex, 1, option);
      setProduct({ ...product, options: nextOptions });
    }
    setOpenEditOption(false);
  };

  useEffect(() => {
    if (productId !== 0) {
      presentLoading();
      ProductService.getProduct(productId).then(
        (response) => {
          dismissLoading();
          const product = response.data;
          if (product._id) {
            setProduct(product);
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    } else {
      setProduct(defaultValues);
    }
  }, [productId]);

  const renderMinMax = (option) => {
    var hasMax = option.max > 0 && option.max < option.list.length;
    if (option.min > 0 || hasMax)
      return (
        "(" +
        (option.min > 0 ? "min=" + option.min + (hasMax ? ", " : "") : "") +
        (hasMax ? "max=" + option.max : "") +
        ")"
      );
    return "";
  };

  return (
    <Layer
      position="center"
      onClickOutside={() => onClose(false)}
      onEsc={onClose}
    >
      <Box
        pad="medium"
        gap="small"
        width="large"
        overflow="scroll"
        style={{ display: "block", maxHeight: 700 }}
      >
        <Box direction="row" justify="between">
          <Heading level={3} margin="none">
            Add Product
          </Heading>
          <CheckBox
            name="active"
            toggle
            checked={product.active}
            onChange={handleCheckboxChange}
            label="Active"
          />
        </Box>
        <Box direction="row" gap="medium">
          <Box gap="medium">
            <Box justify="between" direction="row">
              <Box>
                {category.isMenu && (
                  <FormField label="Sub-Category" width="medium">
                    <TextInput
                      name="subCategory"
                      value={product.subCategory}
                      onChange={handleInputChange}
                    />
                  </FormField>
                )}
                <FormField label="Name" width="medium">
                  <TextInput
                    name="name"
                    value={product.name}
                    onChange={handleInputChange}
                  />
                </FormField>
                {!category.name && (
                  <FormField label="Valid Statuses">
                    <ValidStatusesSelect
                      name="validStatuses"
                      currentValue={product.validStatuses}
                      onChange={handleInputChange}
                    />
                  </FormField>
                )}
              </Box>
              <PhotoUploader
                label="Photo (jpg, 980 × 600, <350K)"
                fitWidth={980}
                fitHeight={600}
                onChange={setPhoto}
                photo={PhotoService.getURL(product.photo) || ""}
              />
            </Box>
            <Text>Description</Text>
            <TextArea
              style={{ height: 150 }}
              name="description"
              value={product.description}
              onChange={handleInputChange}
            />
            <Box direction="row" gap="small">
              <FormField label={"Price"}>
                <PriceInput
                  style={{ border: 0 }}
                  name="price"
                  value={product.price}
                  onChange={handlePriceChange}
                />
              </FormField>
              {!category.isMenu && (
                <FormField label="Tax Rate (%)">
                  <TextInput
                    name="taxPercent"
                    value={product.taxPercent}
                    onChange={handleTaxPercentChange}
                  />
                </FormField>
              )}
              {!category.isMenu && (
                <FormField label={"Tax Amount"}>
                  <PriceInput
                    style={{ border: 0 }}
                    name="tax"
                    value={product.tax}
                    onChange={handleTaxChange}
                  />
                </FormField>
              )}
            </Box>
            {category.isMenu && (
              <Box gap="small">
                {openEditOption && (
                  <AddProductOption
                    option={optionIndex >= 0 && product.options[optionIndex]}
                    onClose={() => setOpenEditOption(false)}
                    onChange={handleOptionChanged}
                  />
                )}
                <Box direction="row" gap="small">
                  <Text>Options</Text>
                  <Button
                    icon={<Add size="small" />}
                    onClick={() => {
                      editOption(-1);
                    }}
                    primary
                    color="status-ok"
                    className="add-button"
                  />
                </Box>
                <Box>
                  {product.options.map((option, idx) => (
                    <Box style={{ marginBottom: 10 }} key={"option-" + idx}>
                      <Box direction="row" gap="small">
                        <Text weight="bold">{option.name}</Text>
                        {(option.min !== undefined ||
                          option.max !== undefined) && (
                          <Text weight="bold">
                            &nbsp;{renderMinMax(option)}
                          </Text>
                        )}
                        <Button
                          icon={<Edit size="small" />}
                          onClick={() => {
                            editOption(idx);
                          }}
                          primary
                          color="status-warning"
                          className="add-button"
                        />
                        <Button
                          primary
                          color="status-error"
                          icon={<Trash size="small" />}
                          onClick={() => removeOption(idx)}
                          className="add-button"
                        />
                      </Box>
                      <Box>
                        {option.list &&
                          option.list.map((item, idx2) => (
                            <Box
                              direction="row"
                              gap="small"
                              key={"item-" + idx2}
                            >
                              <Text>- {item.name}</Text>
                              {item.price && (
                                <Text weight="bold">
                                  +{roundPrice(item.price, true)}
                                </Text>
                              )}
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: "medium", bottom: "small" }}
        >
          <Button
            label="Cancel"
            onClick={() => onClose(false)}
            color="dark-3"
          />
          <Button
            label={productId === 0 ? "Add" : "Update"}
            onClick={handleAdd}
            primary
            color="status-ok"
          />
        </Box>
      </Box>
    </Layer>
  );
}

AddProduct.propTypes = {};
AddProduct.defaultProps = {};
