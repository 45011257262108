import React, { useState } from "react";
import {
  Box,
  Button,
  TextInput,
  Heading,
  Layer,
  FormField,
  Text,
} from "grommet";
import { PriceInput } from "@shared";
import { Add, Trash, Checkmark, Edit } from "grommet-icons";
import { SortableList } from "@shared";
import { roundPrice } from "@common/utils";

const defaultValues = {
  name: "",
  min: 0,
  max: 0,
  list: [],
};

const SortableItem = ({
  item,
  sortIndex,
  onInputItemChange,
  onRemove,
  onStartEditing,
  onStopEditing,
}) => {
  const [editing, setEditing] = useState(
    !item || !item.name || item.name === ""
  );
  const [editedItem, setEditedItem] = useState(item);

  const handleNameChange = ({ target: { value } }) => {
    setEditedItem({ ...editedItem, name: value });
  };
  const handlePriceChange = ({ target: { value } }) => {
    setEditedItem({ ...editedItem, price: parseFloat(value) });
  };
  const handleSave = () => {
    onInputItemChange(sortIndex, editedItem);
    setEditing(false);
    onStopEditing();
  };

  const handleEdit = () => {
    setEditedItem({ ...item });
    setEditing(true);
    onStartEditing();
  };

  return (
    <SortableList.Item id={item.name}>
      {editing ? (
        <Box direction="row" gap="small" style={{ zIndex: 100 }} align="center">
          <SortableList.DragHandle />
          <TextInput
            style={{ width: 200 }}
            value={editedItem.name}
            onChange={handleNameChange}
          />
          <PriceInput
            style={{ width: 100 }}
            value={editedItem.price}
            onChange={handlePriceChange}
          />
          <Button
            primary
            color="status-error"
            style={{ height: 32 }}
            icon={<Trash size="small" />}
            onClick={onRemove}
          />
          <Button
            primary
            color="status-ok"
            style={{ height: 32 }}
            icon={<Checkmark size="small" />}
            onClick={handleSave}
          />
        </Box>
      ) : (
        <Box direction="row" gap="small" style={{ zIndex: 100 }} align="center">
          <SortableList.DragHandle />
          <Text style={{ width: 220 }}>{item.name}</Text>
          <Text style={{ width: 120 }}>
            {item.price ? "+ $" + roundPrice(item.price, true) : ""}
          </Text>
          <Button
            primary
            color="status-warning"
            style={{ height: 32 }}
            icon={<Edit size="small" />}
            onClick={handleEdit}
          />
        </Box>
      )}
    </SortableList.Item>
  );
};

export default function AddProductOption(props) {
  const { onClose, onChange } = props;
  const [option, setOption] = useState(props.option || { ...defaultValues });
  const [maxChanged, setMaxChanged] = useState(option.max > 0);
  const [nbEdit, setNbEdit] = useState(0);

  function handleInputChange({ target: { name, value } }) {
    var values = { ...option };
    values[name] = value;
    setOption(values);
    if (name === "max") setMaxChanged(true);
  }

  function handleInputItemChange(index, item) {
    var values = { ...option, list: [...option.list] || [] };
    values.list.splice(index, 1, item);
    if (!maxChanged) values.max++;
    setOption(values);
  }

  const onSortEnd = (newSortedList) => {
    setOption({ ...option, list: newSortedList });
  };

  const handleAddItem = () => {
    var values = { ...option, list: [...option.list] || [] };
    values.list.push({ name: "" });
    setOption(values);
    setNbEdit(nbEdit + 1);
  };

  const handleRemoveItem = (index) => {
    var values = { ...option, list: [...option.list] };
    if (!values.list) return;
    values.list.splice(index, 1);
    setOption(values);
    setNbEdit(nbEdit - 1);
  };

  const handleSubmit = () => {
    if (option.list && option.max > option.list.length) {
      var nextOption = { ...option, max: option.list.length };
      setOption(nextOption);
      onChange(nextOption);
    } else {
      onChange(option);
    }
  };

  const canSubmit = () => {
    return (
      option.list.length > 0 &&
      option.name.length > 0 &&
      option.max > 0 &&
      nbEdit === 0
    );
  };

  return (
    <Layer position="center" onEsc={onClose} style={{ zIndex: 1000 }}>
      <Box
        pad="medium"
        gap="small"
        width="large"
        overflow="scroll"
        style={{ display: "block", maxHeight: 700 }}
      >
        <Heading level={3} margin="none">
          Add Option
        </Heading>
        <Box gap="medium">
          <Box justify="between" direction="row" gap="small">
            <FormField label="Name" width="medium">
              <TextInput
                name="name"
                value={option.name}
                onChange={handleInputChange}
              />
            </FormField>
            <FormField label="Min # of choices" width="small">
              <TextInput
                name="min"
                value={option.min}
                onChange={handleInputChange}
              />
            </FormField>
            <FormField label="Max # of choices" width="small">
              <TextInput
                name="max"
                value={option.max}
                onChange={handleInputChange}
              />
            </FormField>
          </Box>

          <Text weight="bold">Option items</Text>
          <SortableList
            items={option?.list.map((x) => {
              return { ...x, id: x.name };
            })}
            onChange={onSortEnd}
            renderItem={(item, index) => (
              <SortableItem
                item={item}
                index={index}
                sortIndex={index}
                onInputItemChange={handleInputItemChange}
                onRemove={() => handleRemoveItem(index)}
                onStartEditing={() => setNbEdit(nbEdit + 1)}
                onStopEditing={() => setNbEdit(nbEdit - 1)}
              />
            )}
          />

          <Button
            icon={<Add size="small" />}
            label="Add item"
            style={{ width: 150 }}
            onClick={() => handleAddItem()}
            primary
            color="brand"
          />
        </Box>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: "medium", bottom: "small" }}
        >
          <Button
            label="Cancel"
            onClick={() => onClose(false)}
            color="dark-3"
          />
          <Button
            label={!props.option ? "Add" : "Update"}
            onClick={handleSubmit}
            primary
            color="status-ok"
            disabled={!canSubmit()}
          />
        </Box>
      </Box>
    </Layer>
  );
}

AddProductOption.propTypes = {};
AddProductOption.defaultProps = {};
