import moment from "moment";

export function capitalize(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  return "";
}

export function onDatatableSearch(search, wholeList, setStateFunc) {
  let nextData;
  if (search) {
    // The function below escapes regular expression special characters:
    // [ \ ^ $ . | ? * + ( )
    const escapedText = (text) => {
      text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
      return new RegExp(text, "i");
    };
    const expressions = Object.keys(search).map((property) => ({
      property,
      // Create the regular expression with modified value which handles
      // escaping special characters. Without escaping special characters,
      // errors will appear in the console
      exp: new RegExp(escapedText(search[property]), "i"),
    }));

    nextData = wholeList.filter(
      (d) => !expressions.some((e) => !e.exp.test(d[e.property]))
    );
  } else {
    nextData = wholeList;
  }
  setStateFunc(nextData);
}

export const STATES_OPTIONS_LIST = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export function roundPrice(value = 0, withZeros, zeroMeansEmpty) {
  if (!value && zeroMeansEmpty) return "";
  var res = Math.round(value * 100) / 100;
  if (withZeros) {
    if (res === Math.floor(res)) return res + ".00";
    if (res * 10 === Math.floor(res * 10)) return res + "0";
  }
  return res;
}

export const timeDataUS = [
  { label: "0:00am", value: "00:00" },
  { label: "0:30am", value: "00:30" },
  { label: "1:00am", value: "01:00" },
  { label: "1:30am", value: "01:30" },
  { label: "2:00am", value: "02:00" },
  { label: "2:30am", value: "02:30" },
  { label: "3:00am", value: "03:00" },
  { label: "3:30am", value: "03:30" },
  { label: "4:00am", value: "04:00" },
  { label: "4:30am", value: "04:30" },
  { label: "5:00am", value: "05:00" },
  { label: "5:30am", value: "05:30" },
  { label: "6:00am", value: "06:00" },
  { label: "6:30am", value: "06:30" },
  { label: "7:00am", value: "07:00" },
  { label: "7:30am", value: "07:30" },
  { label: "8:00am", value: "08:00" },
  { label: "8:30am", value: "08:30" },
  { label: "9:00am", value: "09:00" },
  { label: "9:30am", value: "09:30" },
  { label: "10:00am", value: "10:00" },
  { label: "10:30am", value: "10:30" },
  { label: "11:00am", value: "11:00" },
  { label: "11:30am", value: "11:30" },
  { label: "12:00pm", value: "12:00" },
  { label: "12:30pm", value: "12:30" },
  { label: "1:00pm", value: "13:00" },
  { label: "1:30pm", value: "13:30" },
  { label: "2:00pm", value: "14:00" },
  { label: "2:30pm", value: "14:30" },
  { label: "3:00pm", value: "15:00" },
  { label: "3:30pm", value: "15:30" },
  { label: "4:00pm", value: "16:00" },
  { label: "4:30pm", value: "16:30" },
  { label: "5:00pm", value: "17:00" },
  { label: "5:30pm", value: "17:30" },
  { label: "6:00pm", value: "18:00" },
  { label: "6:30pm", value: "18:30" },
  { label: "7:00pm", value: "19:00" },
  { label: "7:30pm", value: "19:30" },
  { label: "8:00pm", value: "20:00" },
  { label: "8:30pm", value: "20:30" },
  { label: "9:00pm", value: "21:00" },
  { label: "8:30pm", value: "21:30" },
  { label: "10:00pm", value: "22:00" },
  { label: "10:30pm", value: "22:30" },
  { label: "11:00pm", value: "23:00" },
  { label: "11:30pm", value: "23:30" },
];

export const timeData = [
  { label: "0:00", value: "00:00" },
  { label: "0:30", value: "00:30" },
  { label: "1:00", value: "01:00" },
  { label: "1:30", value: "01:30" },
  { label: "2:00", value: "02:00" },
  { label: "2:30", value: "02:30" },
  { label: "3:00", value: "03:00" },
  { label: "3:30", value: "03:30" },
  { label: "4:00", value: "04:00" },
  { label: "4:30", value: "04:30" },
  { label: "5:00", value: "05:00" },
  { label: "5:30", value: "05:30" },
  { label: "6:00", value: "06:00" },
  { label: "6:30", value: "06:30" },
  { label: "7:00", value: "07:00" },
  { label: "7:30", value: "07:30" },
  { label: "8:00", value: "08:00" },
  { label: "8:30", value: "08:30" },
  { label: "9:00", value: "09:00" },
  { label: "9:30", value: "09:30" },
  { label: "10:00", value: "10:00" },
  { label: "10:30", value: "10:30" },
  { label: "11:00", value: "11:00" },
  { label: "11:30", value: "11:30" },
  { label: "12:00", value: "12:00" },
  { label: "12:30", value: "12:30" },
  { label: "13:00", value: "13:00" },
  { label: "13:30", value: "13:30" },
  { label: "14:00", value: "14:00" },
  { label: "14:30", value: "14:30" },
  { label: "15:00", value: "15:00" },
  { label: "15:30", value: "15:30" },
  { label: "16:00", value: "16:00" },
  { label: "16:30", value: "16:30" },
  { label: "17:00", value: "17:00" },
  { label: "17:30", value: "17:30" },
  { label: "18:00", value: "18:00" },
  { label: "18:30", value: "18:30" },
  { label: "19:00", value: "19:00" },
  { label: "19:30", value: "19:30" },
  { label: "20:00", value: "20:00" },
  { label: "20:30", value: "20:30" },
  { label: "21:00", value: "21:00" },
  { label: "21:30", value: "21:30" },
  { label: "22:00", value: "22:00" },
  { label: "22:30", value: "22:30" },
  { label: "23:00", value: "23:00" },
  { label: "23:30", value: "23:30" },
];

export const getTimesAfter = (tData, min) => {
  if (!min) return tData;
  const index = tData.findIndex((x) => x.value === min);
  return tData.slice(index);
};

export const CURRENCY_OPTIONS_LIST = [
  { value: "usd", label: "US dollars (USD)" },
  { value: "cad", label: "Canadian dollars (CAD)" },
];

export const LANGUAGE_OPTIONS_LIST = [
  { value: "en", label: "English" },
  { value: "fr", label: "French" },
];

export const PRONOUNS_FR_OPTIONS_LIST = [
  { value: "LE", label: "Le, Au, Du" },
  { value: "LA", label: "La, À la, De La" },
  { value: "L", label: "L', À l', De l'" },
];

export const LOCK_TYPE_OPTIONS_LIST = [
  { value: "ttlock", label: "TTLock" },
  { value: "oracode", label: "Oracode" },
];

export const GRACE_PERIOD_OPTIONS_LIST = [
  { value: -1, label: "No grace period" },
  { value: 10, label: "10 minutes" },
  { value: 20, label: "20 minutes" },
  { value: 30, label: "30 minutes" },
  { value: 40, label: "40 minutes" },
  { value: 50, label: "50 minutes" },
  { value: 60, label: "1 hour" },
  { value: 120, label: "2 hours" },
  { value: 180, label: "3 hours" },
];

export const canCheckout = (booking) => {
  return (
    booking &&
    booking.status.slug !== "noshow" &&
    booking.status.slug !== "cancelled" &&
    booking.status.slug !== "checkedout" &&
    moment().isAfter(booking.checkinDate, "day") &&
    moment().isSameOrBefore(booking.checkoutDate, "day")
  );
};

export const canSendKeys = (booking) => {
  return (
    booking &&
    booking.status.slug !== "noshow" &&
    booking.status.slug !== "cancelled" &&
    booking.status.slug !== "checkedout" &&
    moment().isSameOrBefore(booking.checkoutDate, "day")
  );
};

export function getDuration(value) {
  if (value < 60) return Math.floor(value) + "m";
  var hours = Math.floor(value / 60);
  var minutes = Math.floor(value - hours * 60);
  return hours + "h" + (minutes > 0 ? minutes : "");
}

export const getFilteredPropsObject = (object, filterObj) =>
  Object.keys(filterObj).reduce((obj, key) => {
    obj[key] =
      typeof object[key] === "string" ? object[key].trim() : object[key];
    return obj;
  }, {});
