import React, { useState } from "react";
import { Box, Button, Heading, Layer, Text } from "grommet";
import { roundPrice } from "@common/utils";
import { ChargeService } from "@services";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { presentToastErrorContent } from "@shared/Toast";

export default function ViewMenuRequestModal(props) {
  const { onDecline, onApprove, chargeId, status, hotel } = props;
  const [open, setOpen] = useState(false);
  const [charge, setCharge] = useState();
  const onClose = () => setOpen(false);

  const onOpen = () => {
    presentLoading();
    ChargeService.get(chargeId).then(
      (response) => {
        dismissLoading();
        setCharge(response.data);
        setOpen(true);
      },
      (error) => {
        dismissLoading();
        presentToastErrorContent(error);
      }
    );
  };

  const handleApprove = (approved) => {
    onClose();
    onApprove();
  };

  const handleDecline = (approved) => {
    onClose();
    onDecline();
  };

  const getItemTotalPrice = (item) => {
    var price = 0;
    if (!!item)
      price =
        item.quantity *
        (item.basePrice +
          (item.options || []).reduce(
            (p2, o) =>
              p2 + (o.list || []).reduce((p3, i) => p3 + (i.price || 0), 0),
            0
          ));
    return roundPrice(price);
  };

  const getSubTotalCart = () => {
    if (!!charge.items)
      return roundPrice(
        charge.items.reduce((p, c) => p + getItemTotalPrice(c), 0)
      );
    return 0;
  };

  const getTaxes = () => {
    if (charge.category)
      return roundPrice(
        (getSubTotalCart() * (charge.category.taxPercent || 0)) / 100
      );
    return 0;
  };

  const getTotalCart = () => {
    if (charge.category.fee)
      return getSubTotalCart() + (charge.category.fee || 0) + getTaxes();
    return 0;
  };

  return (
    <div>
      {status === "requested" ? (
        <Button
          label="Review Request"
          onClick={onOpen}
          primary
          color="status-warning"
          alignSelf="start"
        />
      ) : (
        <Button
          label="View"
          onClick={onOpen}
          primary
          color="brand"
          alignSelf="start"
        />
      )}

      {open && charge && (
        <Layer
          position="center"
          onClickOutside={onClose}
          onEsc={onClose}
          className="view-menu-request-modal"
        >
          <Box
            pad="medium"
            gap="small"
            width="large"
            height="stretch"
            alignSelf="start"
          >
            <Heading level={3} margin="none">
              {charge.name}
            </Heading>

            {charge.items && charge.items.length > 0 && (
              <Box align="center" gap="medium">
                {charge.items.map((item) => (
                  <Box
                    key={item.id}
                    className="cartItem"
                    justify="between"
                    direction="row"
                    gap="small"
                  >
                    <Box direction="row" gap="small">
                      <Text className="quantity">{item.quantity}</Text>
                      <Box>
                        <Text className="productName">{item.name}</Text>
                        {item.options.length > 0 && (
                          <Text italic size={13} className="options">
                            {item.options
                              .map((x) => x.list.map((y) => y.name).join(", "))
                              .join(", ")}
                          </Text>
                        )}
                      </Box>
                    </Box>
                    <Text>
                      {hotel.currency}{" "}
                      {roundPrice(getItemTotalPrice(item), true)}
                    </Text>
                  </Box>
                ))}
                <Box className="totals" gap="medium">
                  <Box direction="row" justify="between">
                    <Text>Total</Text>
                    <Text>
                      {hotel.currency} {roundPrice(getSubTotalCart(), true)}
                    </Text>
                  </Box>
                  {!!charge.category &&
                    !!charge.category.fee &&
                    !!charge.category.feeLabel && (
                      <Box direction="row" justify="between">
                        <Text>{charge.category.feeLabel}</Text>
                        <Text>
                          {hotel.currency}{" "}
                          {roundPrice(charge.category.fee, true)}
                        </Text>
                      </Box>
                    )}
                  <Box direction="row" justify="between">
                    <Text>Taxes</Text>
                    <Text>
                      {hotel.currency} {roundPrice(getTaxes(), true)}
                    </Text>
                  </Box>
                  <Box className="total" direction="row" justify="between">
                    <Text bold>Total</Text>
                    <Text>
                      {hotel.currency} {roundPrice(getTotalCart())}
                    </Text>
                  </Box>
                </Box>
              </Box>
            )}

            {charge.status === "requested" ? (
              <Box direction="row" justify="between">
                <Button
                  label="Decline"
                  onClick={() => handleDecline()}
                  primary
                  color="status-error"
                />
                <Button
                  label="Approve"
                  onClick={() => handleApprove()}
                  primary
                  color="status-ok"
                />
              </Box>
            ) : (
              <Button label="Close" onClick={onClose} color="dark-3" />
            )}
          </Box>
        </Layer>
      )}
    </div>
  );
}

ViewMenuRequestModal.propTypes = {};
ViewMenuRequestModal.defaultProps = {};
