import React, { useState, useEffect } from "react";
// import PropTypes from 'prop-types';
import { Box, Form, FormField, Button, Select, Heading } from "grommet";
import { HotelService, GuestService } from "../../services";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { HourInput } from "@shared";
import { useFetchHotel } from "./redux/hooks";
import uniqueTimeZones from "@common/timezones";
import { presentAlertPrompt } from "@shared/Alert";
import {
  GRACE_PERIOD_OPTIONS_LIST,
  getFilteredPropsObject,
} from "@common/utils";

const defaultValues = {
  checkinTime: "15:00",
  checkoutTime: "12:00",
  earlyCheckinTime: "12:00",
  lateCheckoutTime: "15:00",
  timezone: "America/New_York",
  gracePeriod: 30,
};

export default function SettingsHotelTimes(props) {
  const { hotel, fetchHotel } = useFetchHotel();
  const [formValues, setFormValues] = useState(defaultValues);
  const [timezoneOptions, setTimezoneOptions] = useState(uniqueTimeZones);

  useEffect(() => {
    setFormValues({ ...defaultValues, ...hotel });
  }, [hotel]);

  const handleCancel = () => {
    setFormValues({ ...defaultValues, ...hotel });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const request = getFilteredPropsObject(formValues, defaultValues);
    HotelService.updateHotel(request).then(
      (response) => {
        if (response.data.ok) {
          if (
            hotel.checkinTime !== formValues.checkinTime ||
            hotel.checkoutTime !== formValues.checkoutTime
          ) {
            presentAlertPrompt({
              title: "Check-in/out updated",
              message: "Would you like to update future reservations too?",
              onOK: updateFutureBookings,
              onCancel: fetchHotel,
              buttonOKText: "Update",
              buttonOKColor: "status-critical",
            });
          }
          presentToastSuccess("Time Information Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const updateFutureBookings = () => {
    GuestService.updateFutureBookings().then(
      (response) => {
        if (response.data.success) {
          fetchHotel();
          presentToastSuccess("All future reservations have been updated");
        }
      },
      (error) => {
        fetchHotel();
        presentToastErrorContent(error);
      }
    );
  };

  function handleInputChange({ name, value }) {
    var values = { ...formValues };
    values[name] = value;
    setFormValues(values);
  }

  return (
    <div>
      <Heading level="2">Times settings</Heading>
      <Box width="large">
        <Form>
          <FormField label="Timezone Identifier">
            <Select
              name="timezone"
              placeholder="Select"
              value={formValues.timezone}
              options={timezoneOptions}
              onClose={() => setTimezoneOptions(uniqueTimeZones)}
              onChange={({ value: nextValue }) => {
                setFormValues({ ...formValues, timezone: nextValue });
              }}
              onSearch={(text) => {
                const escapedText = text.replace(
                  /[-\\^$*+?.()|[\]{}]/g,
                  "\\$&"
                );
                const exp = new RegExp(escapedText, "i");
                setTimezoneOptions(uniqueTimeZones.filter((o) => exp.test(o)));
              }}
            />
          </FormField>
          <FormField label="Check-in Time">
            <HourInput
              name="checkinTime"
              value={formValues.checkinTime}
              onChange={handleInputChange}
              timezone={formValues.timezone}
            />
          </FormField>
          <FormField label="Check-out Time">
            <HourInput
              name="checkoutTime"
              value={formValues.checkoutTime}
              onChange={handleInputChange}
              timezone={formValues.timezone}
            />
          </FormField>
          <FormField label="Early Check-in Time">
            <HourInput
              name="earlyCheckinTime"
              value={formValues.earlyCheckinTime}
              onChange={handleInputChange}
              timezone={formValues.timezone}
            />
          </FormField>
          <FormField label="Late Check-out Time">
            <HourInput
              name="lateCheckoutTime"
              value={formValues.lateCheckoutTime}
              onChange={handleInputChange}
              timezone={formValues.timezone}
            />
          </FormField>
          <FormField label={"Grace Period"}>
            <Select
              size="medium"
              labelKey="label"
              valueKey={{ key: "value", reduce: true }}
              placeholder="Select"
              value={formValues.gracePeriod}
              options={GRACE_PERIOD_OPTIONS_LIST}
              onChange={({ value: nextValue }) =>
                setFormValues({ ...formValues, gracePeriod: nextValue })
              }
            />
          </FormField>
          <Box direction="row" justify="between" margin={{ top: "medium" }}>
            <Button label="Cancel" onClick={handleCancel} />
            <Button
              type="submit"
              label="Update"
              primary
              onClick={handleOnSubmit}
            />
          </Box>
        </Form>
      </Box>
    </div>
  );
}

SettingsHotelTimes.propTypes = {};
SettingsHotelTimes.defaultProps = {};
