import React, { useState, useEffect } from "react";
import { Box, Button, Heading, CheckBox, Select, FormField } from "grommet";
import HotelService from "../../services/hotel.service";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { useFetchHotel } from "./redux/hooks";
import { PriceInput } from "@shared";
import { CURRENCY_OPTIONS_LIST, getFilteredPropsObject } from "@common/utils";

const defaultValues = {
  deposit: 200,
  expressCheckout: true,
  autoSendEnabled: false,
  requestPhotoID: true,
  requestCardImprint: true,
  offerEarlyCheckin: true,
  offerUpgrade: true,
  offerLateCheckout: true,
  currency: "usd",
  onlineCheckinEnabled: false,
  checkinInvitationDisabled: false,
};

export default function SettingsCheckin() {
  const { hotel } = useFetchHotel();
  const [formValues, setFormValues] = useState(defaultValues);

  useEffect(() => {
    setFormValues({ ...defaultValues, ...hotel });
  }, [hotel]);

  const handleCancel = () => {
    setFormValues({ ...defaultValues, ...hotel });
  };

  function handleInputChange({ target: { name, value } }) {
    var values = { ...formValues };
    values[name] = value;
    setFormValues(values);
  }

  function handleCheckboxChange({ target: { name, checked } }) {
    setFormValues({ ...formValues, [name]: checked });
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const request = getFilteredPropsObject(formValues, defaultValues);
    HotelService.updateHotel(request).then(
      (response) => {
        if (response.data.ok) {
          presentToastSuccess("Information Updated");
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const renderCheckbox = (label, name, disabled) => (
    <CheckBox
      name={name}
      checked={formValues[name]}
      onChange={handleCheckboxChange}
      label={label}
      disabled={disabled}
    />
  );

  const renderSelect = (label, name, options) => (
    <FormField label={label} width="medium">
      <Select
        size="medium"
        labelKey="label"
        valueKey={{ key: "value", reduce: true }}
        placeholder="Select"
        value={formValues[name]}
        options={options}
        onChange={({ value: nextValue }) =>
          setFormValues({ ...formValues, [name]: nextValue })
        }
      />
    </FormField>
  );

  return (
    <div>
      <Heading level="1">Online Check-in</Heading>

      <Box width="large" gap="medium" background="light-2" round pad="medium">
        <Heading level={3} margin="none">
          Default Values
        </Heading>
        {renderCheckbox(
          "Enable Auto send keys when checking in",
          "autoSendEnabled"
        )}
        {renderCheckbox("Enable Online Check-in", "onlineCheckinEnabled")}
        {renderCheckbox(
          "Disable Check-in Invitation",
          "checkinInvitationDisabled",
          !formValues.onlineCheckinEnabled
        )}
        {renderCheckbox(
          "Request Photo ID + Selfie",
          "requestPhotoID",
          !formValues.onlineCheckinEnabled
        )}
        {renderCheckbox(
          "Request Card Imprint",
          "requestCardImprint",
          !formValues.onlineCheckinEnabled
        )}
        {renderCheckbox("Enable Express Checkout", "expressCheckout")}
        {renderCheckbox("Offer Early Check-in", "offerEarlyCheckin")}
        {renderCheckbox("Offer Upgrade", "offerUpgrade")}
        {renderCheckbox("Offer Late Check-out", "offerLateCheckout")}
        <Box gap="medium" direction="row">
          <FormField label="Pre-authorization Deposit">
            <PriceInput
              name="deposit"
              value={formValues.deposit}
              onChange={handleInputChange}
              disabled={!formValues.onlineCheckinEnabled}
            />
          </FormField>
          {renderSelect("Currency", "currency", CURRENCY_OPTIONS_LIST)}
        </Box>
      </Box>
      <Box direction="row" justify="between" margin={{ top: "medium" }}>
        <Button label="Cancel" onClick={handleCancel} />
        <Button type="submit" label="Update" primary onClick={handleOnSubmit} />
      </Box>
    </div>
  );
}
