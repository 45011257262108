import React, { useEffect, useState } from "react";
import "./style.scss";
import { Grid, Box, Text, Button, DropButton, Heading } from "grommet";
import {
  Menu,
  Notification,
  Basket,
  MailOption,
  Alert,
  DocumentUser,
  Lock,
} from "grommet-icons";
import { NavBar } from "./";
import UserService from "../../services/user.service";
import { API_URL } from "../../services/api";
import {
  useSetCurrentUser,
  useFetchHotel,
  useFetchDoors,
  useFetchRoomTypes,
} from "./redux/hooks";
import { getFirebaseToken, onFirebaseMessageListener } from "@common/firebase";
import { presentToastInfo } from "@shared/Toast";

export default function DashboardPage({ children }) {
  const { hotel, fetchHotel } = useFetchHotel();
  const { doors, fetchDoors } = useFetchDoors();
  const { roomTypes, fetchRoomTypes } = useFetchRoomTypes();
  const [sidebar, setSidebar] = useState(true);
  const { currentUser, setCurrentUser, isLoxeAdmin } = useSetCurrentUser();
  const [width, setWidth] = useState(window.innerWidth);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [pushId, setPushId] = useState();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth < 500) setSidebar(false);
  };

  useEffect(() => {
    setSidebar(width > 500);
    window.addEventListener("resize", handleWindowSizeChange);
    getFirebaseToken(setPushId);

    onFirebaseMessageListener()
      .then((payload) => {
        fetchHotel();
        presentToastInfo(payload.notification.title);
      })
      .catch((err) => console.log("failed: ", err));

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [fetchHotel, width]);

  const DropNotifications = () => (
    <Box pad="small" background="white" elevation="medium">
      <Heading>Notifications ({hotel.notifications.length})</Heading>
      {hotel.notifications.map((notif) => (
        <Button
          hoverIndicator="light-1"
          onClick={() => {
            window.location = notif.url;
          }}
          key={notif._id}
          className="notif"
        >
          <Box direction="row" align="center" gap="medium">
            {renderNotifIcon(notif.type)}
            <Box>
              <Text weight="bold">{notif.message}</Text>
              {notif.metaData && notif.metaData.serviceName && (
                <Text color="dark-6" style={{ fontStyle: "italic" }}>
                  {notif.metaData.serviceName}
                </Text>
              )}
              {notif.metaData && notif.metaData.message && (
                <Text color="dark-6" style={{ fontStyle: "italic" }}>
                  {notif.metaData.message}
                </Text>
              )}
              <Box justify="between" direction="row" style={{ minWidth: 250 }}>
                {!notif.metaData.roomNumber ? (
                  <Text style={{ marginRight: 30 }}>
                    {notif.metaData.guestName}
                  </Text>
                ) : (
                  <Text>
                    {notif.metaData.roomNumber &&
                      "Room #" + notif.metaData.roomNumber}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        </Button>
      ))}
    </Box>
  );

  const renderNotifIcon = (type) => {
    switch (type) {
      case "service-new":
        return <Basket color="status-ok" />;
      case "service-cancel":
        return <Alert color="status-error" />;
      case "message":
        return <MailOption color="neutral-3" />;
      case "checkin":
        return <DocumentUser color="accent-4" />;
      case "keys-request":
        return <Lock color="neutral-4" />;
      default:
        return <Notification />;
    }
  };
  useEffect(() => {
    UserService.getUser().then(
      (response) => {
        setCurrentUser(response.data);
        fetchHotel();
        fetchDoors();
        fetchRoomTypes();
      },
      (error) => {
        window.location.href = "/sign-in";
      }
    );
  }, [fetchHotel, fetchDoors, fetchRoomTypes, setCurrentUser]);

  useEffect(() => {
    if (
      pushId &&
      currentUser &&
      (!currentUser.pushIds || !currentUser.pushIds.includes(pushId))
    ) {
      console.log("Send pushId to server", pushId);
      UserService.addPushId(pushId);
    }
  }, [pushId, currentUser]);

  if (window.location.href.indexOf("/pdf/") > 0)
    return <Box margin="large">{children}</Box>;
  else
    return (
      currentUser && (
        <Grid
          fill
          rows={["auto", "flex"]}
          columns={["auto", "flex"]}
          areas={[
            { name: "header", start: [0, 0], end: [1, 0] },
            { name: "sidebar", start: [0, 1], end: [0, 1] },
            { name: "main", start: [1, 1], end: [1, 1] },
          ]}
        >
          <Box
            gridArea="header"
            direction="row"
            align="center"
            justify="between"
            pad={{ horizontal: "medium", vertical: "small" }}
            background="brand"
          >
            <Button onClick={() => setSidebar(!sidebar)}>
              {!sidebar && <Menu size="14px" style={{ marginRight: 10 }} />}
              <Text size="large">
                {sidebar ? "Close menu" : "Loxe FrontDesk"}
              </Text>
              <Text size="small" margin="medium">
                {API_URL === "http://localhost:4444/v1/" && "DEVELOPMENT"}
              </Text>
            </Button>
            <Box direction="row" gap="small" align="center">
              {hotel.notifications && hotel.notifications.length ? (
                <Box
                  round
                  border={{ color: "status-error", size: "1px" }}
                  background="status-error"
                  pad="5px"
                >
                  <DropButton
                    style={{
                      padding: 0,
                      paddingTop: 2,
                      border: 0,
                      width: 22,
                      height: 22,
                    }}
                    label={<Notification size="16px" />}
                    open={openNotifications}
                    onOpen={() => setOpenNotifications(true)}
                    onClose={() => setOpenNotifications(false)}
                    dropContent={<DropNotifications />}
                    dropProps={{
                      align: { top: "bottom" },
                      elevation: "medium",
                      className: "notifs",
                    }}
                  />
                </Box>
              ) : (
                <Box round border={{ color: "white", size: "1px" }} pad="5px">
                  <Notification size="16px" />
                </Box>
              )}
              <Text>{currentUser.username}</Text>
            </Box>
          </Box>
          {sidebar && <NavBar gridArea="sidebar" isLoxeAdmin={isLoxeAdmin} />}
          <Box
            gridArea="main"
            justify="start"
            align="center"
            overflow="auto"
            pad="large"
          >
            {hotel && doors && roomTypes && children}
          </Box>
        </Grid>
      )
    );
}
