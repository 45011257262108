import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextInput,
  Heading,
  Layer,
  CheckBox,
  FormField,
  Text,
  TextArea,
} from "grommet";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { TrainingDataService } from "../../services";

const defaultValues = {
  title: "",
  question: "",
  answer: "",
  internal: false,
};

export default function AddTrainingData(props) {
  const { id: trainingDataId, onClose } = props;
  const [trainingData, setTrainingData] = useState(defaultValues);

  useEffect(() => {
    if (trainingDataId !== 0) {
      presentLoading();
      TrainingDataService.getTrainingDataById(trainingDataId).then(
        (response) => {
          dismissLoading();
          if (response.data && response.data._id) {
            setTrainingData({ ...defaultValues, ...response.data });
          }
        },
        (error) => {
          dismissLoading();
          presentToastErrorContent(error);
        }
      );
    } else {
      setTrainingData(defaultValues);
    }
  }, [trainingDataId]);

  function handleInputChange({ target: { name, value } }) {
    var values = { ...trainingData };
    values[name] = value;
    setTrainingData(values);
  }

  const handleAdd = () => {
    if (trainingDataId === 0) {
      TrainingDataService.add(trainingData).then(
        async (response) => {
          onClose(true);
          presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    } else {
      TrainingDataService.update(trainingData).then(
        async (response) => {
          onClose(true);
          presentToastSuccess("Success");
        },
        (error) => {
          presentToastErrorContent(error);
        }
      );
    }
  };

  function handleCheckboxChange({ target: { name, checked } }) {
    var values = { ...trainingData };
    values[name] = checked;
    setTrainingData(values);
  }

  return (
    <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
      <Box pad="medium" gap="small" width="large">
        <Heading level={3} margin="none">
          Add TrainingData
        </Heading>
        <Box gap="small">
          <FormField
            label={
              <Text>
                {"Title "}
                <Text style={{ fontStyle: "italic", fontSize: 12 }}>
                  {"(not used in AI model)"}
                </Text>
              </Text>
            }
            width="100%"
          >
            <TextInput
              value={trainingData.title}
              name="title"
              onChange={handleInputChange}
            />
          </FormField>
          <FormField label="Question" width="100%">
            <TextInput
              value={trainingData.question}
              name="question"
              onChange={handleInputChange}
            />
          </FormField>
          <FormField label="Answer" width="100%" height="medium">
            <TextArea
              fill
              value={trainingData.answer}
              name="answer"
              onChange={handleInputChange}
              style={{ width: "100%", height: 300 }}
            />
          </FormField>
        </Box>
        <Text>Employees onboarding/training</Text>
        <Box>
          <CheckBox
            name="internal"
            checked={trainingData.internal}
            onChange={handleCheckboxChange}
            label="Internal"
          />
        </Box>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: "medium", bottom: "small" }}
        >
          <Button label="Cancel" onClick={onClose} color="dark-3" />
          <Button
            label={trainingDataId === 0 ? "Add" : "Update"}
            onClick={handleAdd}
            primary
            color="status-ok"
          />
        </Box>
      </Box>
    </Layer>
  );
}
