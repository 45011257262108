import React from "react";

import { Button } from "grommet";
import { presentAlertPrompt } from "./Alert";

export default function ButtonWithConfirm(props) {
  const {
    onOK,
    onCancel,
    buttonOKText,
    alertTitle,
    alertMessage,
    ...buttonProps
  } = props;

  const handleClick = () => {
    presentAlertPrompt({
      title: alertTitle || buttonProps.label,
      message: alertMessage,
      onOK,
      buttonOKText: buttonOKText || "Yes",
      buttonOKColor: buttonProps.color,
      onCancel,
    });
  };

  return <Button {...buttonProps} onClick={handleClick} />;
}
