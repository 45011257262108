import React, { useState } from "react";
import { Box, Button, TextInput, Heading, Layer, Text, Select } from "grommet";
import { Edit } from "grommet-icons";
import { useFetchHotel, useFetchRoomTypes } from "./redux/hooks";

export default function EditDoor(props) {
  const { type, onSubmit, doorToEdit, disabled } = props;
  const { roomTypes } = useFetchRoomTypes();
  const { hotel } = useFetchHotel();
  const [door, setDoor] = useState();
  const [open, setOpen] = useState();

  const onOpen = () => {
    if (doorToEdit) {
      console.log("doorToEdit", doorToEdit);
      setDoor(doorToEdit);
      setOpen(true);
    }
  };
  const onClose = () => setOpen(undefined);

  function handleInputChange({ target: { name, value } }) {
    setDoor({ ...door, [name]: value });
  }
  const handleEdit = () => {
    onClose();
    onSubmit({
      ...door,
      ...(door.type === "room" && { roomType: door.roomType._id }),
      ...(hotel.lockType === "oracode" && door.lockName?.length > 0
        ? { lockId: 1 }
        : { lockId: undefined }),
    });
  };

  return (
    <div>
      <Button
        primary
        icon={<Edit size="small" />}
        size="small"
        color="neutral-3"
        onClick={onOpen}
        disabled={disabled}
      />
      {open && (
        <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
          <Box pad="medium" gap="small" width="medium">
            <Heading level={3} margin="none">
              Edit {type}
            </Heading>
            <Text>Name</Text>
            <TextInput
              name="name"
              value={door.name}
              onChange={handleInputChange}
            />
            {hotel.lockType === "oracode" && (
              <>
                <Text>Oracode Door ID</Text>
                <TextInput
                  name="lockName"
                  value={door.lockName || ""}
                  onChange={handleInputChange}
                />
              </>
            )}
            <Text>Pms Id</Text>
            <TextInput
              name="pmsId"
              value={door.pmsId || ""}
              onChange={handleInputChange}
            />
            {type === "room" && (
              <>
                <Text>Room type</Text>
                <Select
                  name="roomType"
                  placeholder="Select the type of room"
                  value={door.roomType._id}
                  labelKey="name"
                  valueKey={{ key: "_id", reduce: true }}
                  options={roomTypes}
                  onChange={({ value: roomType }) =>
                    setDoor({ ...door, roomType })
                  }
                />
              </>
            )}
            <Box
              as="footer"
              gap="small"
              direction="row"
              align="center"
              justify="end"
              pad={{ top: "medium", bottom: "small" }}
            >
              <Button label="Cancel" onClick={onClose} color="dark-3" />
              <Button
                label="Edit"
                onClick={handleEdit}
                primary
                color="status-ok"
              />
            </Box>
          </Box>
        </Layer>
      )}
    </div>
  );
}
