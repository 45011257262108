import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  DASHBOARD_FETCH_ROOM_TYPES_BEGIN,
  DASHBOARD_FETCH_ROOM_TYPES_SUCCESS,
  DASHBOARD_FETCH_ROOM_TYPES_FAILURE,
  DASHBOARD_FETCH_ROOM_TYPES_DISMISS_ERROR,
} from "./constants";
import RoomTypeService from "../../../services/room-type.service";

export function fetchRoomTypes(args = {}) {
  return (dispatch, getState) => {
    // optionally you can have getState as the second argument
    // don't do anything if it is already loading
    if (getState().dashboard.fetchRoomTypesPending) {
      return Promise.resolve();
    }
    dispatch({
      type: DASHBOARD_FETCH_ROOM_TYPES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      RoomTypeService.getRoomTypes().then(
        (res) => {
          dispatch({
            type: DASHBOARD_FETCH_ROOM_TYPES_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_FETCH_ROOM_TYPES_FAILURE,
            data: { error: err },
          });
          reject(err);
        }
      );
    });

    return promise;
  };
}

export function dismissFetchRoomTypesError() {
  return {
    type: DASHBOARD_FETCH_ROOM_TYPES_DISMISS_ERROR,
  };
}

export function setRoomTypes(roomTypes) {
  return {
    type: DASHBOARD_FETCH_ROOM_TYPES_SUCCESS,
    roomTypes,
  };
}

export function useFetchRoomTypes() {
  const dispatch = useDispatch();

  const { roomTypes, fetchRoomTypesPending, fetchRoomTypesError } = useSelector(
    (state) => ({
      roomTypes: state.dashboard.roomTypes,
      fetchRoomTypesPending: state.dashboard.fetchRoomTypesPending,
      fetchRoomTypesError: state.dashboard.fetchRoomTypesError,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchRoomTypes(...args));
    },
    [dispatch]
  );

  const boundDismissError = useCallback(() => {
    return dispatch(dismissFetchRoomTypesError());
  }, [dispatch]);

  const boundSet = useCallback(() => {
    return dispatch(setRoomTypes());
  }, [dispatch]);

  return {
    roomTypes,
    fetchRoomTypes: boundAction,
    setRoomTypes: boundSet,
    fetchRoomTypesPending,
    fetchRoomTypesError,
    dismissFetchRoomTypesError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_FETCH_ROOM_TYPES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchRoomTypesPending: true,
        fetchRoomTypesError: null,
      };

    case DASHBOARD_FETCH_ROOM_TYPES_SUCCESS:
      // The request is success
      return {
        ...state,
        roomTypes: action.data,
        fetchRoomTypesPending: false,
        fetchRoomTypesError: null,
      };

    case DASHBOARD_FETCH_ROOM_TYPES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchRoomTypesPending: false,
        fetchRoomTypesError: action.data.error,
      };

    case DASHBOARD_FETCH_ROOM_TYPES_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        fetchRoomTypesError: null,
      };

    default:
      return state;
  }
}
