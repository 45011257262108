import axios from "axios";
import { API_URL, authHeader } from "./api";

const ENDPOINT_URL = API_URL + "booking/";

class GuestService {
  getBookings(filter = "") {
    return axios.get(ENDPOINT_URL + "list/" + filter, {
      headers: authHeader(),
    });
  }

  getBooking(bookingId) {
    return axios.get(ENDPOINT_URL + bookingId, { headers: authHeader() });
  }

  addGuest(guest) {
    return axios.post(ENDPOINT_URL, guest, { headers: authHeader() });
  }

  updateBooking(booking) {
    return axios.patch(ENDPOINT_URL, booking, { headers: authHeader() });
  }

  deleteBooking(bookingId) {
    return axios.delete(ENDPOINT_URL + bookingId, { headers: authHeader() });
  }

  checkout(bookingId) {
    return axios.get(ENDPOINT_URL + bookingId + "/checkout", {
      headers: authHeader(),
    });
  }

  //accessDoorIds = [<string>, <string>, ...]
  sendKeys(
    bookingId,
    roomNumber,
    accessDoorIds,
    checkinDate,
    checkoutDate,
    from
  ) {
    let request = {
      roomNumber,
      doors: accessDoorIds,
      checkinDate,
      checkoutDate,
      from,
    };
    return axios.post(ENDPOINT_URL + bookingId + "/sendKeys", request, {
      headers: authHeader(),
    });
  }

  updateFutureBookings() {
    return axios.get(ENDPOINT_URL + "updatefutures", { headers: authHeader() });
  }

  getPdfURL(bookingId) {
    return ENDPOINT_URL + bookingId + "/pdf";
  }

  sendInvitation(bookingId) {
    return axios.get(ENDPOINT_URL + bookingId + "/sendInvite", {
      headers: authHeader(),
    });
  }
}

export default new GuestService();
