import axios from "axios";
import { API_URL, authHeader } from "./api";

const ENDPOINT_URL = API_URL + "events/";

class EventService {
  getEvents(bookingId, cursorEventId) {
    return axios.post(
      ENDPOINT_URL + "list/" + bookingId,
      { cursorEventId },
      { headers: authHeader() }
    );
  }
}

export default new EventService();
