import React, { useEffect, useState, useRef, useCallback } from "react";
import { Box, Button, TextArea, Text, Image, Spinner } from "grommet";
import io from "socket.io-client";
import moment from "moment-timezone";
import { CommunicationService } from "../../services";
import { presentToastErrorContent } from "@shared/Toast";
import { SERVER_URL } from "../../services/api";
import { useFetchHotel } from "./redux/hooks";

import "./ConversationWithAI.scss";

export default function ConversationWithAI() {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [connectedToSocket, setConnectedToSocket] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [isLoadingEarlierMessages, setIsLoadingEarlierMessages] =
    useState(false);
  const [socket, setSocket] = useState(null);
  const { hotel } = useFetchHotel();

  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messageEndRef)
      messageEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  };

  const loadMessages = useCallback(() => {
    CommunicationService.getAIMessages().then(
      async (response) => {
        if (response.data) {
          const {
            messages: nextMessages,
            hasMoreMessages: nextHasMoreMessage,
          } = response.data;
          setMessages(nextMessages);
          setHasMoreMessages(nextHasMoreMessage);
          setTimeout(scrollToBottom, 1000);
        }
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  }, []);

  const loadEarlierMessages = async () => {
    setIsLoadingEarlierMessages(true);
    const { data } = await CommunicationService.getAIMessages(messages[0]._id);
    const { messages: newMessages, hasMoreMessages: nextHasMoreMessage } = data;
    setMessages((prevMessages) => {
      return [...newMessages, ...prevMessages];
    });
    setHasMoreMessages(nextHasMoreMessage);
    setIsLoadingEarlierMessages(false);
  };

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  useEffect(() => {
    const newSocket = io(SERVER_URL, {
      transports: ["websocket"],
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket error:", error);
    });

    newSocket.on("connect", () => {
      console.log("Connected to server");
      setConnectedToSocket(true);
      newSocket.emit("join", { bookingId: hotel._id, isHotel: true });
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("receiveMessage", async (message) => {
        console.log("receiveMessage", message);
        if (!message.fromHotel) {
          setMessages((prevMessages) => [...prevMessages, message]);
          setTimeout(scrollToBottom, 500);
        }
      });
    }
  }, [socket]);

  const handleSendMessage = async () => {
    if (currentMessage?.trim().length === 0) return;
    try {
      const { data: newMessages } = await CommunicationService.sendAIMessage(
        currentMessage.trim()
      );
      if (newMessages) {
        setCurrentMessage("");
        setMessages([...messages, ...newMessages]);
        setTimeout(scrollToBottom, 500);
      }
    } catch (error) {
      presentToastErrorContent(error.message);
    }
  };

  return (
    <>
      <Box direction="row" justify="between">
        {!connectedToSocket && (
          <Button
            label="Refresh"
            onClick={() => loadMessages()}
            primary
            color="brand"
            alignSelf="end"
          />
        )}
      </Box>
      <Box gap="small" width="100%">
        <Box
          height="medium"
          round
          width="xlarge"
          overflow="scroll"
          pad="medium"
          margin="medium"
          background="light-1"
        >
          <Box flex="grow">
            {hasMoreMessages && (
              <Button
                style={
                  isLoadingEarlierMessages
                    ? {
                        paddingLeft: 90,
                        width: 205,
                      }
                    : {}
                }
                label={
                  isLoadingEarlierMessages ? (
                    <Spinner size="xsmall" />
                  ) : (
                    "Load earlier messages"
                  )
                }
                onClick={loadEarlierMessages}
                active
                alignSelf="center"
                small
              />
            )}
            {messages &&
              messages.map((message) => (
                <Box key={message._id}>
                  {message.photo && (
                    <Image
                      className="photo"
                      src={message.photo}
                      fit="cover"
                      width="200px"
                      round="small"
                      alignSelf={message.fromHotel ? "end" : "start"}
                    />
                  )}
                  {message.text && (
                    <Box
                      className="message"
                      width="fit-content"
                      alignSelf={message.fromHotel ? "end" : "start"}
                      background={message.fromHotel ? "accent-1" : "light-4"}
                      round="small"
                      pad="small"
                    >
                      {message.text}
                    </Box>
                  )}
                  <Text
                    className="date"
                    size="11px"
                    style={{ fontStyle: "italic" }}
                    alignSelf={message.fromHotel ? "end" : "start"}
                  >
                    {moment.tz(message.created, hotel.timezone).format("llll")}{" "}
                    {message.fromHotel && `• ${message.status.toLowerCase()}`}
                  </Text>
                </Box>
              ))}
          </Box>
          <div ref={messageEndRef}></div>
        </Box>
        <Box direction="row">
          <Box
            background="light-1"
            margin="small"
            animation="fadeIn"
            height="100px"
            width="100%"
          >
            <TextArea
              fill
              value={currentMessage}
              onChange={(event) => setCurrentMessage(event.target.value)}
              placeholder="Type your message here..."
              resize={false}
            />
          </Box>
          <Button
            label="Send"
            onClick={handleSendMessage}
            primary
            alignSelf="center"
          />
        </Box>
      </Box>
    </>
  );
}
