import React, { useContext } from "react";
import { ResponsiveContext, Header, Box, Heading } from "grommet";
import { ConversationWithAI } from "./";

export default function AskAI(props) {
  var contextSize = useContext(ResponsiveContext);
  if (contextSize !== "small") contextSize = "medium";

  return (
    <Box
      align="center"
      justify="start"
      pad="medium"
      gap="medium"
      flex="grow"
      margin={{ bottom: "300px" }}
    >
      <Header
        align="center"
        direction="row"
        fill="horizontal"
        className="details-header"
      >
        <Heading level="2" className="heading">
          Ask your AI Front Desk Clerk
        </Heading>
      </Header>
      <ConversationWithAI />
    </Box>
  );
}
