import React, { useEffect, useMemo, useState } from "react";
// import PropTypes from 'prop-types';
import { Select, TextInput } from "grommet";
import { timeDataUS, timeData, getTimesAfter } from "@common/utils";

export default function HourInput(props) {
  const {
    name,
    value,
    onChange,
    placeholder,
    disabled,
    timezone,
    min,
    withEmptyLabel,
    filter,
  } = props;
  const [disabledLabel, setDisabledLabel] = useState("");

  const options = useMemo(() => {
    const options = (
      withEmptyLabel ? [{ label: withEmptyLabel, value: "" }] : []
    ).concat(
      timezone?.startsWith("America")
        ? getTimesAfter(timeDataUS, min)
        : getTimesAfter(timeData, min)
    );
    return filter ? options.filter(filter) : options;
  }, [withEmptyLabel, timezone, min, filter]);

  useEffect(() => {
    if (disabled && value) {
      setDisabledLabel(value);
    }
  }, [value, disabled]);

  return !disabled ? (
    <Select
      plain
      name={name}
      placeholder={placeholder || "Select"}
      value={value || ""}
      labelKey="label"
      valueKey={{ key: "value", reduce: true }}
      options={options}
      onChange={({ value: nextValue }) => onChange({ name, value: nextValue })}
    />
  ) : (
    <TextInput plain value={disabledLabel || ""} />
  );
}

HourInput.propTypes = {};
HourInput.defaultProps = {};
