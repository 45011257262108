import React from "react";
import {
  Box,
  Button,
  Heading,
  Layer,
  Spinner,
  TableCell,
  Text,
  Tip,
} from "grommet";
import {
  AddCircle,
  CheckboxSelected,
  Refresh,
  StatusGood,
  StatusWarning,
  Subtract,
  SubtractCircle,
} from "grommet-icons";

export default function KeysSentReportModal({
  onClose,
  report,
  isOracode,
  onRetryWithOfflineCodes,
}) {
  const { ekeysDoors, passcodesDoors, notifStatus } = report || {};

  const renderIcon = (result) => {
    const dropProps = { align: { left: "right" } };
    if (typeof result === "boolean") {
      return result ? (
        <CheckboxSelected color="status-ok" size="20px" />
      ) : (
        <Subtract color="text" size="20px" />
      );
    }
    switch (result?.status) {
      case "keep":
        return (
          <Tip content="No change" dropProps={dropProps}>
            <StatusGood color="blue-1" />
          </Tip>
        );
      case "delete":
        return (
          <Tip content="Removed" dropProps={dropProps}>
            <SubtractCircle color="status-warning" />
          </Tip>
        );
      case "add":
        return (
          <Tip content="Added" dropProps={dropProps}>
            <AddCircle color="status-ok" />
          </Tip>
        );
      case "update":
        return (
          <Tip content="Updated" dropProps={dropProps}>
            <Refresh color="status-ok" />
          </Tip>
        );
      case "error":
        return (
          <Tip content={result.message} dropProps={dropProps}>
            <StatusWarning color="status-error" />
          </Tip>
        );
      default:
        return null;
    }
  };

  return (
    report && (
      <Layer position="center" onClickOutside={onClose} onEsc={onClose}>
        <Box
          pad="medium"
          gap="small"
          width="large"
          height="stretch"
          alignSelf="start"
        >
          <Heading level={3} margin="none">
            Keys Sent Report
          </Heading>
          {report.error ? (
            <Text color="status-error">Error: {report.error}</Text>
          ) : report.loading ? (
            <Box height="medium" align="center" justify="center">
              <Spinner size="large" margin="small" />
              <Text>Loading… This may take a minute to complete.</Text>
            </Box>
          ) : (
            <>
              <Box
                background="light-3"
                round="small"
                pad="medium"
                margin={{ bottom: "small" }}
              >
                <Heading level={4} margin={{ bottom: "small", top: "none" }}>
                  Communications
                </Heading>
                <table style={{ width: "fit-content" }}>
                  <tbody>
                    <tr>
                      <TableCell>
                        <Text>Text: </Text>
                      </TableCell>
                      <TableCell>{renderIcon(notifStatus.text)}</TableCell>
                    </tr>
                    <tr>
                      <TableCell>
                        <Text>Email: </Text>
                      </TableCell>
                      <TableCell>{renderIcon(notifStatus.email)}</TableCell>
                    </tr>
                    <tr>
                      <TableCell>
                        <Text>Push: </Text>
                      </TableCell>
                      <TableCell>{renderIcon(notifStatus.push)}</TableCell>
                    </tr>
                  </tbody>
                </table>
              </Box>

              <Box
                background="light-3"
                round="small"
                pad="medium"
                margin={{ bottom: "small" }}
              >
                <Heading level={4} margin={{ bottom: "small", top: "none" }}>
                  Passcodes
                </Heading>
                <table style={{ width: "fit-content" }}>
                  <tbody>
                    {passcodesDoors
                      .sort((a) =>
                        a.door?.type === "area" || a.code?.door.type === "area"
                          ? 1
                          : -1
                      )
                      .map((result) => (
                        <tr key={result.code?.door._id || result.door._id}>
                          <TableCell>
                            {result.door?.type === "area" ||
                            result.code?.door.type === "area"
                              ? result.code?.door.name || result.door.name
                              : `Room ${
                                  result.code?.door.name || result.door.name
                                }`}
                          </TableCell>
                          <TableCell>
                            <Box
                              border={{ size: "small", color: "text" }}
                              round
                              pad={{ horizontal: "2px" }}
                            >
                              <Text weight="bold">
                                {result.code?.keyboardPwd}
                              </Text>
                            </Box>
                          </TableCell>
                          <TableCell>{renderIcon(result)}</TableCell>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Box>
              {!isOracode && (
                <Box
                  background="light-3"
                  round="small"
                  pad="medium"
                  margin={{ bottom: "small" }}
                >
                  <Heading level={4} margin={{ bottom: "small", top: "none" }}>
                    Mobile Keys
                  </Heading>
                  <table style={{ width: "fit-content" }}>
                    <tbody>
                      {ekeysDoors
                        .sort((a) => (a.door?.type === "area" ? 1 : -1))
                        .map((result) => (
                          <tr key={result.door?.name}>
                            <TableCell>
                              <Text>
                                {result.door?.type === "area"
                                  ? result.door?.name
                                  : `Room ${result.door?.name}`}
                              </Text>
                            </TableCell>
                            <TableCell>
                              <Text>{renderIcon(result)}</Text>
                            </TableCell>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
              )}
            </>
          )}
          <Box direction="row" gap="small" alignSelf="center">
            {passcodesDoors?.some((r) => r.status === "error") && (
              <Button
                label="Retry allowing offline codes"
                onClick={onRetryWithOfflineCodes}
                primary
                color="status-warning"
                small
                alignSelf="center"
              />
            )}
            <Button
              label="Close"
              onClick={onClose}
              color="dark-3"
              disabled={report.loading}
              small
              alignSelf="center"
            />
          </Box>
        </Box>
      </Layer>
    )
  );
}
