import React from "react";
import { Layer, Box, Text, Heading, Button } from "grommet";

class Alert extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      title: "",
      message: "",
      onCancel: null,
      onOK: null,
      buttonOKText: "OK",
      buttonOKColor: "brand",
    };
    window.alert = this;
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOK = this.handleOK.bind(this);
  }

  present(title, message, onOK, buttonOKText, buttonOKColor, onCancel) {
    this.setState({
      open: true,
      message: message || "",
      title: title || "",
      onCancel,
      onOK,
      buttonOKText: buttonOKText || "OK",
      buttonOKColor: buttonOKColor || "brand",
    });
  }

  handleOK() {
    if (this.state.onOK) this.state.onOK();
    this.setState({ open: false });
  }

  handleCancel() {
    if (this.state.onCancel) this.state.onCancel();
    this.setState({ open: false });
  }

  render() {
    return (
      this.state.open && (
        <Layer
          position="center"
          onClickOutside={this.handleCancel}
          onEsc={this.handleCancel}
        >
          <Box pad="medium" gap="small" width="medium">
            <Heading level={3} margin="none">
              {this.state.title}
            </Heading>
            <Text>{this.state.message}</Text>
            <Box
              as="footer"
              gap="small"
              direction="row"
              align="center"
              justify="end"
              pad={{ top: "medium", bottom: "small" }}
            >
              {this.state.onOK && (
                <Button
                  label="Cancel"
                  onClick={this.handleCancel}
                  color="dark-3"
                />
              )}
              <Button
                label={this.state.buttonOKText}
                onClick={this.handleOK}
                primary
                color={this.state.buttonOKColor}
              />
            </Box>
          </Box>
        </Layer>
      )
    );
  }
}

export default Alert;
export function presentAlertPrompt({
  title,
  message,
  onOK,
  buttonOKText,
  buttonOKColor,
  onCancel,
}) {
  window.alert.present(
    title,
    message,
    onOK,
    buttonOKText,
    buttonOKColor,
    onCancel
  );
}
