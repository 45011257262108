import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import { Image as ImageGrommet, Text, Button, Box } from "grommet";
import { FileDrop } from "react-file-drop";
import ReactCrop, { makeAspectCrop, centerCrop } from "react-image-crop";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function PhotoUploader(props, ref) {
  const { onChange, fill, photo, width, label, blackbg, fitWidth, fitHeight } =
    props;
  const [image, setImage] = useState(photo);
  const [dropping, setDropping] = useState(null);
  const [cropping, setCropping] = useState(null);
  const fileInput = useRef(null);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    aspect: fitWidth / fitHeight,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [file, setFile] = useState();
  const [aspect, setAspect] = useState(fitWidth / fitHeight);

  useEffect(() => {
    setImage(photo);
    setCompletedCrop(null);
  }, [photo]);

  const cropImage = () => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;

    if (!(image instanceof HTMLImageElement)) {
      console.error("image is not an HTMLImageElement");
      return;
    }

    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    var newWidth = crop.width,
      newHeight = crop.height;
    if (newWidth > fitWidth || newHeight > fitHeight) {
      var aspectRatio = fitWidth / fitHeight;
      if (aspectRatio > 1) {
        newWidth = fitWidth;
        newHeight = Math.round(newWidth / aspectRatio);
      } else {
        newHeight = fitHeight;
        newWidth = Math.round(newHeight * aspectRatio);
      }
    }

    canvas.width = newWidth * pixelRatio;
    canvas.height = newHeight * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      newWidth,
      newHeight
    );

    setImage(canvas.toDataURL(file.type));
  };

  const getBlobFromCanvas = (canvas) =>
    new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          blob.name = file.name;
          blob.lastModified = file.lastModified;
          resolve(blob);
        } else {
          reject(new Error("Canvas is empty"));
        }
      }, file.type);
    });

  useImperativeHandle(ref, () => ({
    hasImage: () => {
      return !!image;
    },
    clear: () => {
      setImage(null);
      fileInput.current.value = null;
    },
  }));

  function handleFileSelect(files, evt) {
    if (files.length === 0) return;

    var reader = new FileReader();
    reader.onloadend = function (e) {
      setImage(e.target.result);
    };
    reader.readAsDataURL(files[0]);
    setFile(files[0]);
    setCropping(true);
  }

  const generateUpload = async () => {
    if (!completedCrop || !previewCanvasRef.current) {
      return;
    }
    if (onChange) {
      cropImage();
      const newFile = await getBlobFromCanvas(previewCanvasRef.current);
      onChange(newFile);
    }
    setCropping(false);
  };

  function onImageLoad(e) {
    imgRef.current = e.currentTarget;
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const onCloseCrop = () => {
    setImage(photo);
    setCropping(false);
  };

  return (
    <div>
      <Box className={"croppingLayer" + (!cropping ? "" : " show")}>
        <Box gap="medium" align="center" pad="medium" overflow="scroll">
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            style={{ maxHeight: 800 }}
            aspect={aspect}
          >
            <img ref={imgRef} src={image} onLoad={onImageLoad} />
          </ReactCrop>
          <Box direction="row" justify="between" width="100%">
            <Button
              label="Cancel"
              alignSelf="center"
              color="brand"
              onClick={onCloseCrop}
            />
            <Text size="xsmall">Crop or move the image, and press OK</Text>
            <Button
              label="OK"
              alignSelf="center"
              primary
              color="brand"
              onClick={generateUpload}
              disabled={
                !completedCrop || !completedCrop.width || !completedCrop.height
              }
            />
          </Box>
        </Box>
        <canvas
          ref={previewCanvasRef}
          style={{
            display: "none",
            width: Math.round((completedCrop && completedCrop.width) || 0),
            height: Math.round((completedCrop && completedCrop.height) || 0),
          }}
        />
      </Box>
      {label && <div className="label">{label}</div>}
      <div
        className={`home-image-loader ${fill ? "fill" : ""}`}
        style={{ width: width || 200 }}
        onClick={() => {
          fileInput.current.click();
        }}
      >
        <FileDrop
          onDrop={(files, event) => handleFileSelect(files, event)}
          onFrameDragEnter={(event) => setDropping(true)}
          onFrameDragLeave={(event) => setDropping(false)}
          onFrameDrop={(event) => setDropping(false)}
          className={`file-drop ${blackbg ? "blackbg" : ""}`}
        >
          {!image || dropping ? (
            <div className="drop-text">
              <strong>
                Drop a file here!
                <br />
                or click to browse files
              </strong>
            </div>
          ) : (
            <ImageGrommet src={image} className="image" />
          )}
        </FileDrop>
        <input
          type="file"
          id="files"
          ref={fileInput}
          className="file-input"
          onChange={(event) => handleFileSelect(event.target.files, event)}
        />
      </div>
    </div>
  );
}

export default forwardRef(PhotoUploader);
