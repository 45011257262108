export const SERVER_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4444"
    : "https://api.loxe.io";

export const API_URL = SERVER_URL + "/v1/";

export function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    // for Node.js Express back-end
    return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
}
