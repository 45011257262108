import React, { useState } from "react";
import Popover from "react-popover";
import { Box, Text } from "grommet";

export default function SecondaryDoorIcon({ pkey, mainRoomName }) {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      key={pkey}
      isOpen={open}
      body={`This is a secondary door for ${mainRoomName}. It will automatically be added with the main room door.`}
      children={
        <Box
          key={pkey}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
          background="neutral-3"
          round
          width={{ min: "20px" }}
          align="center"
          justify="center"
        >
          <Text color="white" size="xsmall">
            S
          </Text>
        </Box>
      }
    />
  );
}
