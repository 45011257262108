import axios from "axios";
import { API_URL, authHeader } from "./api";

const ENDPOINT_URL = API_URL + "training/";

class TrainingDataService {
  add(trainingData) {
    return axios.post(ENDPOINT_URL, trainingData, { headers: authHeader() });
  }

  getTrainingData() {
    return axios.get(ENDPOINT_URL + "list", { headers: authHeader() });
  }

  deleteTrainingData(trainingDataId, toRemove) {
    return axios.delete(ENDPOINT_URL + trainingDataId + "/" + toRemove, {
      headers: authHeader(),
    });
  }

  update(trainingData) {
    return axios.patch(ENDPOINT_URL, trainingData, { headers: authHeader() });
  }

  getTrainingDataById(trainingDataId) {
    return axios.get(ENDPOINT_URL + trainingDataId, { headers: authHeader() });
  }

  createModel() {
    return axios.get(ENDPOINT_URL + "create-model", { headers: authHeader() });
  }
}

export default new TrainingDataService();
