import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import {} from "./redux/hooks";
import { Box, Header, Heading, Button, DataTable, Text } from "grommet";
import { Add, Edit, Trash } from "grommet-icons";
import { GuestService } from "../../services";
import { capitalize, onDatatableSearch } from "@common/utils";
import { GuestModal, ImportBookingsButton } from "./";
import {
  BookingDates,
  AppInstalled,
  NoPhoneIcon,
  BookingStatus,
  NoResults,
} from "@shared/";
import { presentAlertPrompt } from "@shared/Alert";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { CAN_IMPORT_FROM_PMS } from "@common/constants";

const fetchGuests = (setGuestList, setAllGuestList) => {
  presentLoading();
  GuestService.getBookings("pending").then(
    (response) => {
      var list = response.data.map((booking) => ({
        id: booking._id,
        bookingId: booking.bookingId,
        name:
          capitalize(booking.firstname) + " " + capitalize(booking.lastname),
        appInstalled: booking.appInstalled,
        room: booking.roomNumber ? booking.roomNumber : "-",
        dates: (
          <BookingDates
            checkinDate={booking.checkinDate}
            checkoutDate={booking.checkoutDate}
          />
        ),
        status: booking.status,
        statusName: booking.status.name,
        phone: booking.phone,
      }));
      dismissLoading();
      setGuestList(list);
      setAllGuestList(list);
    },
    (error) => {
      dismissLoading();
      setGuestList([]);
      setAllGuestList([]);
    }
  );
};

export default function ReservationsPage() {
  const [guestList, setGuestList] = useState([]);
  const [allGuestList, setAllGuestList] = useState([]);
  const [guestModal, setGuestModal] = useState(false);
  const [guestModalProps, setGuestModalProps] = useState({
    guestId: 0,
    sendKeys: false,
  });

  const columns = [
    {
      property: "bookingId",
      header: "Ref.",
      search: true,
    },
    {
      property: "name",
      header: "Name",
      search: true,
      render: (datum) => (
        <Box direction="row" gap="small" align="center">
          <Text>{datum.name}</Text>
          {datum.appInstalled && <AppInstalled pkey={datum.id} />}
          {!datum.phone && !datum.appInstalled && (
            <NoPhoneIcon pkey={`nophone-${datum.id}`} />
          )}
        </Box>
      ),
    },
    {
      property: "room",
      header: "Room",
      align: "center",
      search: true,
    },
    {
      property: "dates",
      header: "Booking Dates",
      // render: datum =>
      //   datum.date && new Date(datum.date).toLocaleDateString('en-US'),
      align: "center",
    },
    {
      property: "statusName",
      header: "Status",
      align: "center",
      search: true,
      render: (datum) => <BookingStatus status={datum.status} />,
    },
    {
      property: "actions",
      header: "Actions",
      align: "center",
      render: (datum) => (
        <Box direction="row" gap="medium">
          <Button
            hoverIndicator="accent-4"
            icon={<Edit size="small" />}
            size="small"
            primary
            color="status-warning"
            onClick={() => handleGuestDetails(datum.id)}
          />
          {/* <Button
            hoverIndicator="accent-1"
            icon={<License size="small" />}
            size="small"
            primary
            color="status-ok"
            onClick={() => handleGuestModalKeys(datum.id)}
          /> */}
          <Button
            hoverIndicator="dark-1"
            icon={<Trash size="small" />}
            size="small"
            primary
            color="status-critical"
            onClick={() => handleRemoveBooking(datum.id)}
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    fetchGuests(setGuestList, setAllGuestList);
  }, []);

  const handleAddGuest = () => {
    setGuestModalProps({ guestId: 0, sendKeys: false });
    setGuestModal(true);
  };

  const handleGuestModalKeys = (guestId) => {
    setGuestModalProps({ guestId, sendKeys: true });
    setGuestModal(true);
  };

  const handleGuestModalClose = (refresh) => {
    setGuestModal(false);
    if (refresh) fetchGuests(setGuestList, setAllGuestList);
  };

  const handleGuestDetails = (guestId) => {
    window.location.href = "expected/" + guestId;
  };

  const handleRemoveBooking = (guestId) => {
    function removeGuest() {
      GuestService.deleteBooking(guestId).then(
        (response) => {
          presentToastSuccess(response.data.message);
          fetchGuests(setGuestList, setAllGuestList);
        },
        (error) => {
          presentToastErrorContent(error);
          fetchGuests(setGuestList, setAllGuestList);
        }
      );
    }

    presentAlertPrompt({
      title: "Are you sure?",
      message:
        "This will cancel the reservation only on LOXE's system, not on your PMS.",
      onOK: removeGuest,
      buttonOKText: "Delete",
      buttonOKColor: "status-critical",
    });
  };

  return (
    <Box align="center" justify="start" fill pad="medium">
      {guestModal && (
        <GuestModal
          guestId={guestModalProps.guestId}
          onClose={handleGuestModalClose}
          sendKeys={guestModalProps.sendKeys}
        />
      )}
      <Header
        align="center"
        direction="row"
        justify="between"
        gap="medium"
        fill="horizontal"
      >
        <Heading level="2">
          Expected Reservations{" "}
          {allGuestList && "(" + allGuestList.length + ")"}
        </Heading>
        <Box direction="row" gap="small">
          {CAN_IMPORT_FROM_PMS && (
            <ImportBookingsButton
              onComplete={() => fetchGuests(setGuestList, setAllGuestList)}
            />
          )}
          <Button
            icon={<Add size="small" />}
            label="Add"
            primary
            onClick={handleAddGuest}
          />
        </Box>
      </Header>
      <Box fill="horizontal">
        {allGuestList.length ? (
          <DataTable
            columns={columns}
            data={guestList}
            primaryKey="id"
            onClickRow={(event) => {
              //console.log(event.target.localName);
              // if (event.target.localName!=='button' && event.target.localName!=='svg')
              //   handleGuestModal(event.datum.id);
            }}
            onSearch={(search) =>
              onDatatableSearch(search, allGuestList, setGuestList)
            }
          />
        ) : (
          <NoResults>
            This list is empty. <br />
            Start adding or importing reservations.
          </NoResults>
        )}
      </Box>
    </Box>
  );
}

ReservationsPage.propTypes = {};
ReservationsPage.defaultProps = {};
