import axios from "axios";
import { API_URL, authHeader } from "./api";

const ENDPOINT_URL = API_URL + "auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(ENDPOINT_URL + "signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  ssoLogin(partner, token) {
    return axios
      .post(ENDPOINT_URL + "sso-signin", {
        partner,
        token,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  //user = { username, email, password }
  register(user) {
    return axios.post(ENDPOINT_URL + "signup", user, { headers: authHeader() });
  }

  resetPassword(userId) {
    return axios.get(ENDPOINT_URL + "reset/" + userId, {
      headers: authHeader(),
    });
  }

  forgot(email) {
    return axios.post(ENDPOINT_URL + "forgot", { email });
  }

  change(token, password) {
    return axios.post(ENDPOINT_URL + "change", { token, password });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
