import React, { useEffect, useState } from "react";
import { Box, Header, Button, Heading, Text, Image } from "grommet";
import { Trash, Edit, Add, Download } from "grommet-icons";
import { presentToastSuccess, presentToastErrorContent } from "@shared/Toast";
import { AddRoomType } from "./";
import { RoomTypeService, PmsService, PhotoService } from "../../services";
import { useFetchRoomTypes } from "./redux/hooks";
import { presentAlertPrompt } from "@shared/Alert";
import { presentLoading, dismissLoading } from "@shared/Loading";
import { SortableList } from "@shared";
import noPhoto from "../../images/nophoto.jpg";
import { CAN_IMPORT_FROM_PMS } from "@common/constants";

export default function SettingsRoomTypes(props) {
  const { roomTypes } = useFetchRoomTypes();
  const [list, setList] = useState([]);
  const [roomTypeId, setRoomTypeId] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    setList(roomTypes || []);
  }, [roomTypes]);

  const SortableItem = ({ value }) => (
    <SortableList.Item id={value._id}>
      <Box direction="row" gap="medium" justify="between">
        <Box alignContent="center" className="room-types-column">
          <SortableList.DragHandle />
        </Box>
        <Box
          direction="row"
          gap="medium"
          width="400px"
          className="room-types-column"
          align="start"
        >
          <Box height="70px" width="70px">
            <Image
              height="70px"
              width="70px"
              fit="cover"
              src={PhotoService.getURL(value.photo) || noPhoto}
              className="room-types-image"
              onClick={() => handleEdit(value._id)}
            />
          </Box>
          <Box gap="small" width="330px">
            <Text weight="bold">{value.name}</Text>
            <Text truncate color="dark-6" style={{ fontStyle: "italic" }}>
              {value.description}
            </Text>
          </Box>
        </Box>
        <Box className="room-types-column" width="100px" align="center">
          {value.order === 0 ? (
            "-"
          ) : (
            <Box align="center">
              <Text size="xsmall">Upgrade</Text>
              <Text>${Math.round(value.upgradePrice * 100 || 0) / 100}</Text>
            </Box>
          )}
        </Box>
        <Box className="room-types-column" width="90px" align="center">
          <Text size="xsmall" style={{ textAlign: "center" }}>
            Early Check-in
          </Text>
          <Text>${Math.round(value.earlyCheckinPrice * 100 || 0) / 100}</Text>
        </Box>
        <Box className="room-types-column" width="90px" align="center">
          <Text size="xsmall" style={{ textAlign: "center" }}>
            Late Check-out
          </Text>
          <Text>${Math.round(value.lateCheckoutPrice * 100 || 0) / 100}</Text>
        </Box>
        <Box direction="row" gap="medium" className="room-types-column">
          <Button
            icon={<Edit size="small" />}
            size="small"
            label="Edit"
            alignSelf="center"
            primary
            color="status-warning"
            onClick={() => handleEdit(value._id)}
          />
          <Button
            icon={<Trash size="small" />}
            size="small"
            label="Remove"
            alignSelf="center"
            primary
            color="status-critical"
            onClick={() => handleRemoveRoomType(value._id)}
          />
        </Box>
      </Box>
    </SortableList.Item>
  );

  const onRefresh = () => {
    RoomTypeService.getRoomTypes().then(
      (response) => {
        setList(response.data);
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleEditModalClose = (refresh) => {
    if (refresh) onRefresh();

    setOpenEdit(false);
  };

  const handleRemoveRoomType = (roomTypeId) => {
    presentAlertPrompt({
      title: "Are you sure?",
      message: "Do you really want to delete this type?",
      onOK: () => {
        removeRoomType(roomTypeId);
      },
      buttonOKText: "Delete",
      buttonOKColor: "status-critical",
    });
  };

  const removeRoomType = (roomTypeId) => {
    RoomTypeService.deleteRoomType(roomTypeId).then(
      (response) => {
        onRefresh();
        presentToastSuccess("Room type successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleEdit = (roomTypeId) => {
    setRoomTypeId(roomTypeId);
    setOpenEdit(true);
  };

  const handleAdd = () => {
    setRoomTypeId(0);
    setOpenEdit(true);
  };

  const handleImport = () => {
    presentLoading();
    PmsService.importRoomTypes().then(
      (response) => {
        onRefresh();
        dismissLoading();
      },
      (error) => {
        dismissLoading();
      }
    );
  };

  const onSortEnd = (newSortedList) => {
    setList(newSortedList);
    const oldList = list;
    const listIds = newSortedList.map((x) => x._id);
    RoomTypeService.updateOrder(listIds).then(
      (response) => {
        // onRefresh();
      },
      (error) => {
        setList(oldList);
        presentToastErrorContent(error);
      }
    );
  };

  return (
    <Box
      gap="medium"
      justify="start"
      className="settings-room-types"
      flex="grow"
    >
      <Heading alignSelf="start" level="2">
        Room Types
      </Heading>
      <Header
        align="center"
        direction="row"
        justify="between"
        gap="medium"
        fill="horizontal"
      >
        {CAN_IMPORT_FROM_PMS && (
          <Button
            icon={<Download size="small" />}
            label="Import from PMS"
            primary
            color="status-warning"
            onClick={handleImport}
          />
        )}
        <Button
          primary
          icon={<Add size="small" />}
          size="small"
          label="Add"
          onClick={handleAdd}
        />
        {openEdit && (
          <AddRoomType roomTypeId={roomTypeId} onClose={handleEditModalClose} />
        )}
      </Header>

      <SortableList
        items={list.map((x) => {
          return { ...x, id: x._id };
        })}
        onChange={onSortEnd}
        renderItem={(item) => <SortableItem value={item} />}
      />
    </Box>
  );
}

SettingsRoomTypes.propTypes = {};
SettingsRoomTypes.defaultProps = {};
