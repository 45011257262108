import axios from "axios";
import { API_URL, authHeader } from "./api";

const ENDPOINT_URL = API_URL + "key/";

class EKeyService {
  delete(keyId) {
    return axios.delete(ENDPOINT_URL + keyId, {
      headers: authHeader(),
    });
  }
}

export default new EKeyService();
