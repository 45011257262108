import { App } from "../features/home";
import homeRoute from "../features/home/route";
import dashboardRoute from "../features/dashboard/route";

const childRoutes = [homeRoute, dashboardRoute];

const getChildRoutes = (route) =>
  route.childRoutes.map((x) => {
    return {
      path: route.path + "/" + x.path,
      component: (
        <App>
          <route.component>
            <x.component />
          </route.component>
        </App>
      ),
    };
  });

const routes = childRoutes.reduce((a, c) => [...a, ...getChildRoutes(c)], []);

export default routes;
