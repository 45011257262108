import React, { useEffect, useState } from "react";
import { Box, Header, DataTable, Button, Heading } from "grommet";
import { Trash, Edit, Add, StatusGood, Undo, Magic } from "grommet-icons";
import {
  presentToastSuccess,
  presentToastErrorContent,
  presentToastWarning,
} from "@shared/Toast";
import { AddTrainingData } from "./";
import { TrainingDataService } from "../../services";
import { presentAlertPrompt } from "@shared/Alert";
import TrainingDataStatus from "@shared/TrainingDataStatus";
import { useFetchHotel } from "./redux/fetchHotel";

export default function SettingsTrainingData() {
  const [list, setList] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [trainingDataId, setTrainingDataId] = useState(0);
  const { hotel } = useFetchHotel();

  const columns = [
    {
      property: "internal",
      header: "Internal",
      render: (datum) =>
        datum.internal ? (
          <Box align="center">
            <StatusGood size="medium" color="status-ok" />
          </Box>
        ) : (
          <></>
        ),
    },
    {
      property: "title",
      header: "Title",
      primary: true,
    },
    {
      property: "question",
      header: "Question",
      render: (datum) => datum.question?.substring(0, 100),
      size: "medium",
    },
    {
      property: "status",
      header: "Status",
      render: (datum) => (
        <TrainingDataStatus
          trainingData={datum}
          onClick={() => handleEdit(datum._id)}
        />
      ),
    },
    {
      property: "actions",
      render: (datum) => (
        <Box direction="row" justify="between" gap="medium">
          <Button
            icon={<Edit size="small" />}
            size="small"
            primary
            color="status-warning"
            onClick={() => handleEdit(datum._id)}
          />

          <Button
            icon={
              datum.willBeRemoved ? (
                <Undo size="small" />
              ) : (
                <Trash size="small" />
              )
            }
            size="small"
            primary
            color={datum.willBeRemoved ? "accent-1" : "status-critical"}
            onClick={() =>
              handleRemoveTrainingData(datum._id, !datum.willBeRemoved)
            }
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    onRefresh();
  }, []);

  const onRefresh = () => {
    TrainingDataService.getTrainingData().then(
      (response) => {
        setList(response.data);
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleEditModalClose = (refresh) => {
    if (refresh) onRefresh();

    setOpenEdit(false);
  };

  const handleAdd = () => {
    setTrainingDataId(0);
    setOpenEdit(true);
  };

  const handleEdit = (trainingDataId) => {
    setTrainingDataId(trainingDataId);
    setOpenEdit(true);
  };

  const handleRemoveTrainingData = (trainingDataId, toRemove) => {
    if (toRemove) {
      presentAlertPrompt({
        title: "Are you sure?",
        message:
          "This training data will be marked to be removed on the next model update.",
        onOK: () => {
          removeTrainingData(trainingDataId, true);
        },
        buttonOKText: "Delete",
        buttonOKColor: "status-critical",
      });
    } else {
      removeTrainingData(trainingDataId, false);
    }
  };

  const removeTrainingData = (trainingDataId, toRemove) => {
    TrainingDataService.deleteTrainingData(trainingDataId, toRemove).then(
      (response) => {
        onRefresh();
        presentToastSuccess("TrainingData successfully removed.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  const handleCreateModel = () => {
    if (hotel.aiEnabled) {
      presentAlertPrompt({
        title: "Are you sure?",
        message: "The model will be created with the current training data.",
        onOK: () => {
          createModel();
        },
        buttonOKText: "Create new model",
        buttonOKColor: "status-warning",
      });
    } else {
      presentToastWarning(
        "Sorry, this feature is not enabled for your account. Please contact your LOXE account manager."
      );
    }
  };

  const createModel = () => {
    TrainingDataService.createModel().then(
      (response) => {
        onRefresh();
        presentToastSuccess("Model successfully created.");
      },
      (error) => {
        presentToastErrorContent(error);
      }
    );
  };

  return (
    <Box gap="medium" justify="start">
      <Heading alignSelf="start" level="2">
        AI Training
      </Heading>
      <Header
        align="center"
        direction="row"
        justify="between"
        gap="medium"
        fill="horizontal"
      >
        <Button
          primary
          icon={<Add size="small" />}
          size="small"
          label="Add Training Data"
          onClick={handleAdd}
        />
        <Button
          icon={<Magic size="small" />}
          label="Create new AI model"
          primary
          color="status-warning"
          onClick={handleCreateModel}
        />
        {openEdit && (
          <AddTrainingData id={trainingDataId} onClose={handleEditModalClose} />
        )}
      </Header>
      <Box align="center" fill="horizontal">
        <DataTable columns={columns} data={list} primaryKey="id" />
      </Box>
    </Box>
  );
}
